import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import {
  useMessages,
  useAddMessage,
  Message,
  useMessageTemplates,
  useMessagesBulkView,
  useToggleNeedsAttention
} from '../../api'
import {
  Tag,
  Typography,
  Space,
  App,
  Button,
  Table,
  Form,
  Input,
  Select,
  Card,
  Spin,
  Switch
} from 'antd'
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import { LoadingIndicator } from '../../components/Loader'
import styles from './styles.module.css'
import MarkdownPreview from '@uiw/react-markdown-preview';
import CustomMDEditor from '../../components/CustomMarkdownEditor';
import { ParticipantCalendar } from '../../components/Calendar';

const {
    Title,
    Text
  } = Typography
  
  const { Option } = Select
  const { TextArea } = Input

export const Messages: React.FC<{ activeTab: string, canEmail: boolean, canSms: boolean, canChat: boolean }> = ({ activeTab, canEmail, canSms, canChat }) => {
    const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
    const { data: messages, isLoading: messagesLoading } = useMessages(organizationId, studyId, virtualUserId)
    const { data: messageTemplates, isLoading: messagesTemplatesLoading } = useMessageTemplates(organizationId, studyId)
  
    const [form] = Form.useForm()
  
    const [newType, setNewType] = useState<Message['message_type'] | undefined>(canChat ? "chat" : canEmail ? "email" : canSms ? "sms" : undefined)
    const [newBody, setNewBody] = useState<string>('')
    const { message: messageApi } = App.useApp();
  
    const [addMessage, {
      isLoading: addMessageLoading
    }] = useAddMessage({
      onError: () => {
        void messageApi.error('Failed to send message')
      },
      onSuccess: () => {
        void messageApi.success('Successfully sent message')
        setNewType("chat")
        setNewBody("")
      }
    })
  
    const [bulkViewMessages] = useMessagesBulkView({    
      onError: () => {
      void messageApi.error('Failed to mark messages as viewed')
    }
  })
  
  const [toggleNeedsAttention] = useToggleNeedsAttention({
    onError: () => {
      void messageApi.error(`Failed to toggle needs attention ${messages?.messages_require_attention ? 'off' : 'on'}`)
    },
    onSuccess: () => {
      void messageApi.success(`Successfully toggled needs attention ${messages?.messages_require_attention ? 'off' : 'on'}`)
    }
  })
  
  useEffect(() => {
    if (activeTab === "messages" && messages?.messages.filter(message => !message.viewed_by_recipient).length) void bulkViewMessages({organizationId, studyId, virtualUserId})
  }, [activeTab, messages?.messages])
  
    const columns: ColumnsType<Message> = [
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: function Datetime(created_at) {
          return <Text style={{ whiteSpace: 'nowrap' }}>{moment.unix(created_at).format('MMM DD LT')}</Text>
        }
      },
      {
        title: 'Type',
        dataIndex: 'message_type',
        key: 'message_type',
        render: function MessageType(message_type) {
          return <Tag>{message_type}</Tag>
        }
      },
      {
        title: 'From',
        key: 'from',
        render: function From(_, message) {
          return (
            message.message_direction === "inbound" ? (
              <Space direction='horizontal'>
                Participant
                <RightCircleOutlined />
              </Space>
            ) : (
              <Space direction='horizontal'>
                <LeftCircleOutlined />
                {message.member_user_email.split("@")[0]}
              </Space>
            )
          )
        },
      },
      {
        title: 'Body',
        dataIndex: 'body',
        key: 'body',
        render: function Body(body: string) {
          return <MarkdownPreview source={body} />
        }
      },
      {
        title: 'Viewed',
        dataIndex: 'viewed_by_recipient',
        key: 'viewed_by_recipient',
        render: function Viewed(viewed_by_recipient, message) {
          if (message.message_direction === "outbound") {
            return viewed_by_recipient ? <EyeOutlined /> : <EyeInvisibleOutlined />
          }
  
          if (viewed_by_recipient) {
            return <EyeOutlined />
          }
  
          return <EyeInvisibleOutlined />
        }
      },
    ];
  
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <ParticipantCalendar organizationId={organizationId} studyId={studyId} virtualUserId={virtualUserId} />
        <Title level={3} style={{ marginTop: 0 }}>Messages</Title>
        <Card title="Send Message">
          <Form form={form} onFinish={() => newType && addMessage({ organizationId, studyId, virtualUserId, message: { message_type: newType, body: newBody ?? '' } })}>
            <Form.Item label="Type" required={true}>
              <Select value={newType} style={{ width: 200 }} onChange={(value) => setNewType(value)}>
                {canChat && <Option value="chat">Chat</Option>}
                {canEmail && <Option value="email">Email</Option>}
                {canSms && <Option value="sms">SMS</Option>}
              </Select>
            </Form.Item>
            <Form.Item label="Body">
              {newType === 'email' 
              ? <CustomMDEditor autofillCommands="study" height={400} value={newBody} onChange={setNewBody} />
              : <TextArea rows={4} value={newBody} onChange={({ target }) => setNewBody(target.value)} />}
            </Form.Item>
            <Form.Item label="Use Template">
              {messagesTemplatesLoading || !messageTemplates ? (
                <Spin />
              ) : (
                <Select defaultValue={""} style={{ width: 200 }} onChange={(value) => {
                  const messageTemplate = messageTemplates.find(messageTemplate => messageTemplate.id === value);
                  if (messageTemplate) {
                    setNewBody(messageTemplate.body)
                  }
                }}>
                  <Option value="">None</Option>
                  {messageTemplates.map((messageTemplate) => (
                    <Option key={messageTemplate.id} value={messageTemplate.id}>{messageTemplate.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" disabled={addMessageLoading || !newType || !newBody}>Submit</Button>
            </Form.Item>
          </Form>
        </Card>
        <Space className={styles.NeedsAttentionToggle} direction='horizontal' align="center">
          <Title style={{margin: 0}} level={4}>Needs Attention</Title>
          <Switch checked={messages?.messages_require_attention} onChange={() => void toggleNeedsAttention({organizationId, studyId, virtualUserId, needsAttention: !messages?.messages_require_attention})} />
        </Space>
        <Table
          pagination={false}
          className={styles.MessageTable}
          {...(messagesLoading) && {
            loading: {
              indicator: <LoadingIndicator />
            }
          }}
          dataSource={messages?.messages ?? []}
          rowClassName={(record) => record.message_direction === 'inbound' ? 'participantMessage' : ''}
          columns={columns}
          rowKey="id"
        />
      </Space>
    )
  }