import React from 'react'
import { API_VERSION, apiVersion } from '../api'
import { PageHeader } from "@ant-design/pro-components"
import {
  Descriptions,
  Space,
  Typography,
} from 'antd'

const { Title } = Typography

const AppInfo: React.FC = () => {
  const { data: version } = apiVersion()

  return (
    <PageHeader title={
      <Space direction="vertical" size="small">
        <Title level={3} style={{ margin: 0 }}>App Info</Title>
      </Space>
      }
    >
      <Space direction="vertical" size="large" style={{ width: "66%" }}>
        <Descriptions
          bordered
          title="Application"
          size="small"
          column={2}
        >
          <Descriptions.Item label="API">{process.env.REACT_APP_PORTAL_API_CONFIG_URL}</Descriptions.Item>
          <Descriptions.Item label="Client API Version">{API_VERSION}</Descriptions.Item>
          <Descriptions.Item label="Client Code Version">{process.env.REACT_APP_GIT_SHA}</Descriptions.Item>
          <Descriptions.Item label="Server API Version">{version?.version}</Descriptions.Item>
          <Descriptions.Item label="Server Code Version">{version?.code}</Descriptions.Item>
          <Descriptions.Item label="Firmware Version">{version?.fw_version}</Descriptions.Item>
          <Descriptions.Item label="Kortex Version">{version?.kortex_version}</Descriptions.Item>
        </Descriptions>
      </Space>
    </PageHeader>
  )
}

export default AppInfo
