import React, { useMemo, useRef } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import moment from 'moment';
import { useMessageViewed, useStudyInbox, StudyInboxMessage } from 'src/api';
import { Table, Typography, Button, Space, Spin, Input, InputRef, App } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, LeftCircleOutlined, SearchOutlined, RightCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import styles from './styles.module.css';
import { StudyCalendar } from '../Calendar';

const { Title } = Typography

const getFromValue = (message: StudyInboxMessage) => message.message_direction === "inbound" ? "Participant" : message.member_user_email?.split("@")[0];

interface StudyInboxTableProps {
  organizationId: string;
  studyId: string;
  messages: StudyInboxMessage[];
}

const StudyInboxTable: React.FC<StudyInboxTableProps> = ({ organizationId, studyId, messages }) => {
  const { message: messageApi } = App.useApp();
  const [markViewed] = useMessageViewed({
    onError: () => {
      void messageApi.error('Failed to mark message viewed')
    },
    onSuccess: () => {
      void messageApi.success('Successfully marked message viewed')
    }
  })

  const nameSearchInput = useRef<InputRef>(null);

  const fromFilters = useMemo(() => [...new Set(messages.map(getFromValue))].map(value => ({
      text: value,
      value,
  })) || [], [messages]);

  const columns: ColumnsType<StudyInboxMessage> = [
    {
      title: 'Participant',
      dataIndex: 'participant_id',
      key: 'participant_id',
      filterDropdown: function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters })  {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={nameSearchInput}
              placeholder={`Search by Participant Id`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
      )},
      filterIcon: function FilterIcon(filtered) {
        return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      },
      onFilter: (value, record) => record.participant_id
        ? record.participant_id.toString().toLowerCase().includes(value.toString().toLowerCase())
        : false,
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => nameSearchInput.current?.select(), 100);
        }
      },
      ellipsis: true,
    },
    {
      title: 'Message',
      dataIndex: 'body',
      key: 'message',
      render: function Message(_, message) {
        return (
          <Link 
            to={generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId/messages', {
              organizationId,
              studyId,
              virtualUserId: message.virtual_user_id
            })} 
            className={styles.MessageBodyLink}
          >
            {message.body}
          </Link>
        )
      },
      ellipsis: true,
    },
    {
      title: 'From',
      key: 'from',
      filters: fromFilters,
      onFilter: (value, record) => getFromValue(record) === value,
      render: function From(_, message) {
        return (
          message.message_direction === "inbound" ? (
            <Space direction='horizontal'>
              Participant
              <RightCircleOutlined />
            </Space>
          ) : (
            <Space direction='horizontal'>
              <LeftCircleOutlined />
              {message.member_user_email?.split("@")[0]}
            </Space>
          )
        )
      },
    },
    {
      title: 'Sent',
      dataIndex: 'created_at',
      key: 'sent',
      sorter: (a, b) => Number(a.created_at) - Number(b.created_at),
      render: text => moment.unix(text).format('LLL'),
    },
    {
      title: 'Read Status',
      dataIndex: 'viewed_by_recipient',
      key: 'read',
      sorter: (a, b) => Number(a.viewed_by_recipient) - Number(b.viewed_by_recipient),
      render: function Viewed(viewed_by_recipient, message) {
        if (message.message_direction === "outbound") {
          return viewed_by_recipient ? <EyeOutlined /> : <EyeInvisibleOutlined />
        }

        if (viewed_by_recipient) {
          return <EyeOutlined />
        }

        return <Button icon={<EyeInvisibleOutlined />} onClick={() => markViewed({ organizationId, studyId, virtualUserId: message.virtual_user_id, messageId: message.id })}>Mark Viewed</Button>
      },
    }
  ];

  return (
    <Table columns={columns} dataSource={messages} rowKey="id" />
  )
}

const StudyInbox: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: messagesWithoutKey, isLoading } = useStudyInbox(organizationId, studyId);

  const messages = useMemo(() => messagesWithoutKey?.map(message => ({...message, key: message.id})), [messagesWithoutKey]);

  const activeMessages = useMemo(() => messages?.filter(message => message.message_direction === 'inbound') || [], [messages]);

  const pendingMessages = useMemo(() => messages?.filter(message => message.message_direction === 'outbound') || [], [messages]);

  if(isLoading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <StudyCalendar organizationId={organizationId} studyId={studyId} />
      <Title style={{ marginTop: 0 }} level={3}>Active</Title>
      <StudyInboxTable organizationId={organizationId} studyId={studyId} messages={activeMessages} />
      <Title style={{ marginTop: 0 }} level={3}>Pending</Title>
      <StudyInboxTable organizationId={organizationId} studyId={studyId} messages={pendingMessages} />
    </Space>
  );
}

export default StudyInbox