import React, { useMemo } from "react";
import { CalendarEvent, ReminderStatus, ResponseStatus, useParticipantCalendar, useStudyCalendar } from "../../api";
import { Alert, Collapse, CollapseProps, Empty, List, Space, Spin, Tag, Typography } from "antd";
import { generatePath, Link } from "react-router-dom";
import moment from 'moment'

const { Text, Title } = Typography;

const DEFAULT = {
    text: "Unknown",
    color: "gray",
}

const ParticipantStatusMap: Record<ResponseStatus, { text: string, color: string }> = {
    "needsAction": {
        text: "Invited",
        color: "blue",
    },
    "declined": {
        text: "Declined",
        color: "red",
    },
    "tentative": {
        text: "Tentative",
        color: "yellow",
    },
    "accepted": {
        text: "Accepted",
        color: "green",
    },
}

const ReminderStatusMap: Record<ReminderStatus, { text: string, color: string }> = {
    "sent_email": {
        text: "Sent Reminder Email",
        color: "blue",
    },
    "email_error": {
        text: "Error Sending Reminder Email",
        color: "red",
    },
    "sent_sms": {
        text: "Sent Reminder SMS",
        color: "blue",
    },
    "sms_error": {
        text: "Error Sending Reminder SMS",
        color: "red",
    },
    "timed_out": {
        text: "Reminder Confirmation Timed Out",
        color: "red",
    },
    "confirmed": {
        text: "Confirmed Reminder",
        color: "green",
    },
}

const lookupParticipantStatus = (status?: ResponseStatus): { text: string, color: string } => {
    if (!status) return DEFAULT;
    return ParticipantStatusMap[status] || DEFAULT;
}

export const StudyCalendar: React.FC<{ organizationId: string, studyId: string }> = ({ organizationId, studyId }) => {
    const { data, isLoading, isError } = useStudyCalendar(organizationId, studyId);

    const eventsByDay = useMemo(() => {
        if (!data) return [];

        const eventsByDay: Record<string, { date: string, events: CalendarEvent[] }> = {};
        data.forEach(event => {
            const date = moment.unix(event.start).startOf('day').format("YYYY-MM-DD");

            if (!eventsByDay[date]) {
                eventsByDay[date] = {
                    date,
                    events: [],
                }
            }

            eventsByDay[date].events.push(event);
        });

        return Object.values(eventsByDay);
    }, [data]);

    const items: CollapseProps['items'] = useMemo(() =>
        eventsByDay.map(({ date, events }) => ({
            key: date,
            label: moment(date).format("dddd, MMMM D YYYY"),
            children: (
                <List
                    dataSource={events}
                    renderItem={event => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={`${moment.unix(event.start).format("h:mm A")} - ${moment.unix(event.end).format("h:mm A")}`}
                            title={
                                <Space direction="horizontal" size="large">
                                    {event.virtual_user_id && event.participant_id && (
                                        <Link to={generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId', { organizationId, studyId, virtualUserId: event.virtual_user_id })}>
                                            {event.participant_id}
                                        </Link>
                                    )}
                                    {event.status === "declined" && // only care about declined statuses for now
                                        <Tag color={lookupParticipantStatus(event.status).color}>{lookupParticipantStatus(event.status).text}</Tag>
                                    }
                                    {event.reminder_status && (
                                        <Tag color={ReminderStatusMap[event.reminder_status].color}>{ReminderStatusMap[event.reminder_status].text}</Tag>
                                    )}
                                </Space>
                            }
                            description={event.name}
                        />
                    </List.Item>
                    )}
                />
            )
        }))
    , [eventsByDay])

    if (isError) {
        return <Alert type="error" showIcon message="Error loading events" />
    }

    if (isLoading || typeof data === 'undefined') {
        return <Spin />
    }

    if (data.length === 0) {
        return <Empty description="No events" />
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Title style={{ marginTop: 0 }} level={3}>Upcoming Visits</Title>
            <Collapse
                size="small"
                items={items}
                defaultActiveKey={items?.[0]?.key}
            />
        </Space>
    )
}

export const ParticipantCalendar: React.FC<{ organizationId: string, studyId: string, virtualUserId: string }> = ({ organizationId, studyId, virtualUserId }) => {
    const { data, isLoading, isError } = useParticipantCalendar(organizationId, studyId, virtualUserId);

    if (isError) {
        return <Alert type="error" showIcon message="Error loading events" />
    }

    if (isLoading || typeof data === 'undefined') {
        return <Spin />
    }

    if (data.length === 0) {
        return <Empty description="No events" />
    }

    return (
        <List
            header={<Title style={{ marginTop: 0 }} level={3}>Upcoming Visits</Title>}
            dataSource={data}
            renderItem={event => (
            <List.Item>
                <List.Item.Meta
                    avatar={
                        <>  
                            <Text strong>{moment.unix(event.start).format("dddd, MMMM D YYYY")}</Text>
                            <br />
                            {moment.unix(event.start).format("h:mm A")} - {moment.unix(event.end).format("h:mm A")}
                        </>
                    }
                    title={
                        <Space direction="horizontal" size="large">
                            {event.status === "declined" && // only care about declined statuses for now
                                <Tag color={lookupParticipantStatus(event.status).color}>{lookupParticipantStatus(event.status).text}</Tag>
                            }
                            {event.reminder_status && (
                                <Tag color={ReminderStatusMap[event.reminder_status].color}>{ReminderStatusMap[event.reminder_status].text}</Tag>
                            )}
                        </Space>
                    }
                    description={event.name}
                />
            </List.Item>
            )}
        />
    )
}