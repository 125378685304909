export default {
  ME: "/me",
  OAUTH: "/oauth_server/authorize",
  API_VERSION: "/version",
  ME_MEMBERSHIP: "/organizations",
  /** profile */
  PROFILE_UPDATE_EMAIL: "/me/profile/email/change",
  PROFILE_VERIFY_UPDATED_EMAIL: "/me/profile/email/change/verify",
  /** notifications */
  NOTIFICATIONS: "/notifications",
  NOTIFICATIONS_READ: "/notification/read",
  /** data streams */
  DATA_TRANSFORMS: "/data_transforms",
  DATA_STREAM_CREATE: "/data_stream",
  DATA_STREAM: "/organization/:organizationId/study/:studyId/data_stream/:dataStreamId",
  DATA_STREAM_DELETE: "/data_stream/:dataStreamId",
  DATA_STREAM_UPDATE: "/data_stream/:dataStreamId",
  DATA_STREAM_TOGGLE_ALWAYS_READY: "/data_stream/:dataStreamId/toggle_always_ready",
  /** organization */
  ORGANIZATION: "/organization/:organizationId",
  ORGANIZATION_MEMBERS: "/organization/:organizationId/members",
  ORGANIZATION_REMOVE_MEMBER: "/organization/:organizationId/member/:memberUserId",
  ORGANIZATION_UPDATE_MEMBER: "/organization/:organizationId/member/:memberUserId",
  ORGANIZATION_ADD_MEMBER: "/organization/:organizationId/member",
  ORGANIZATION_MEMBER_INVITES: "/organization/:organizationId/invitations",
  ORGANIZATION_CREATE_STUDY: "/organization/:organizationId/study",
  ORGANIZATION_DATA_STREAMS: "/organization/:organizationId/data_streams",
  ORGANIZATION_DATA_STREAM_ADD: "/organization/:organizationId/data_stream/:activationKey",
  ORGANIZATION_ADD: "/organizations",
  ORGANIZATION_EXTERNAL_API_KEY: "/organization/:organizationId/external_api_key",
  /** study transform */
  STUDY_TRANSFORM_PIPELINE: "/organization/:organizationId/study/:studyId/pipeline",
  STUDY_TRANSFORM_PIPELINE_STATUS: "/organization/:organizationId/study/:studyId/session/:sessionId/pipeline/unique_status",
  ANALYSIS_PIPELINE_STATUS: "/organization/:organizationId/study/:studyId/pipeline/status",
  /** study assets **/
  ORGANIZATION_ASSETS_DOWNLOAD: "/organization/:organizationId/study/:studyId/session/:sessionId/assets/:pipelineName/download",
  /** organization studies */
  ORGANIZATION_STUDY: "/organization/:organizationId/study/:studyId",
  ORGANIZATION_STUDIES: "/organization/:organizationId/studies",
  ORGANIZATION_STUDY_ALL_DATA_STREAMS: "/organization/:organizationId/study/:studyId/all_data_streams",
  ORGANIZATION_STUDY_DATA_STREAMS: "/organization/:organizationId/study/:studyId/data_streams",
  ORGANIZATION_STUDY_PARTICIPANT: "/organization/:organizationId/study/:studyId/participant/:virtualUserId",
  ORGANIZATION_STUDY_PARTICIPANTS: "/organization/:organizationId/study/:studyId/participants",
  ORGANIZATION_STUDY_PARTICIPANTS_EXPORT: "/organization/:organizationId/study/:studyId/participants/export",
  ORGANIZATION_STUDY_PARTICIPANTS_IMPORT: "/organization/:organizationId/study/:studyId/participants/import",
  ORGANIZATION_STUDY_INVITE: "/organization/:organizationId/study/:studyId/invite",
  ORGANIZATION_STUDY_REINVITE: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/invite",
  ORGANIZATION_STUDY_MEMBERS: "/organization/:organizationId/study/:studyId/members",
  ORGANIZATION_STUDY_AVAILABLE_MEMBERS: "/organization/:organizationId/study/:studyId/members/available",
  ORGANIZATION_STUDY_MEMBER: "/organization/:organizationId/study/:studyId/member/:memberUserId",
  ORGANIZATION_STUDY_SURVEYS: "/organization/:organizationId/study/:studyId/surveys",
  ORGANIZATION_STUDY_SURVEY: "/organization/:organizationId/study/:studyId/survey",
  ORGANIZATION_STUDY_SURVEY_UPDATE: "/organization/:organizationId/study/:studyId/survey/:surveyId",
  /** study checklists */
  ORGANIZATION_STUDY_CHECKLISTS: "/organization/:organizationId/study/:studyId/checklist/list",
  ORGANIZATION_STUDY_CHECKLIST_CREATE: "/organization/:organizationId/study/:studyId/checklist",
  ORGANIZATION_STUDY_CHECKLIST_GET: "/organization/:organizationId/study/:studyId/checklist/:checklistId",
  ORGANIZATION_STUDY_CHECKLIST_UPDATE: "/organization/:organizationId/study/:studyId/checklist/:checklistId",
  ORGANIZATION_STUDY_CHECKLIST_DELETE: "/organization/:organizationId/study/:studyId/checklist/:checklistId",
  ORGANIZATION_STUDY_CHECKLIST_LOGS_GET: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/checklist/logs",
  ORGANIZATION_STUDY_CHECKLIST_LOG_UPDATE: "/organization/:organizationId/study/:studyId/checklist/log/:checklistLogId",
  ORGANIZATION_STUDY_CHECKLIST_LOG_MISSED: "/organization/:organizationId/study/:studyId/checklist/log/:checklistLogId/missed",
  ORGANIZATION_STUDY_CREATE_SESSION: "/user/:virtualUserId/session",
  ORGANIZATION_STUDY_UPLOAD_IMAGE: "/organization/:organizationId/study/:studyId/upload_image",
  ORGANIZATION_STUDY_VIRTUAL_USER_PROTOCOL: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/protocol",
  /** study protocols */
  ORGANIZATION_STUDY_PROTOCOLS: "/organization/:organizationId/study/:studyId/protocols",
  ORGANIZATION_STUDY_PROTOCOL_CREATE: "/organization/:organizationId/study/:studyId/protocols",
  ORGANIZATION_STUDY_PROTOCOL_UPDATE: "/organization/:organizationId/study/:studyId/protocol/:protocolId",
  ORGANIZATION_STUDY_PROTOCOL_DELETE: "/organization/:organizationId/study/:studyId/protocol/:protocolId",
  ORGANIZATION_STUDY_PROTOCOLS_IMPORT: "/organization/:organizationId/study/:studyId/protocols/import",
  ORGANIZATION_STUDY_PROTOCOL_EXPORT: "/organization/:organizationId/study/:studyId/protocol/:protocolId/export",
  /** study experiments */
  ORGANIZATION_STUDY_EXPERIMENTS: "/organization/:organizationId/study/:studyId/experiments",
  ORGANIZATION_STUDY_EXPERIMENT_CREATE: "/organization/:organizationId/study/:studyId/experiment/",
  ORGANIZATION_STUDY_EXPERIMENT_UPDATE: "/organization/:organizationId/study/:studyId/experiment/:experimentId",
  ORGANIZATION_STUDY_EXPERIMENT_DELETE: "/organization/:organizationId/study/:studyId/experiment/:experimentId",
  /** study session */
  ORGANIZATION_STUDY_FLOW_UI: "/organization/:organizationId/study/:studyId/flow_ui",
  ORGANIZATION_STUDY_SESSIONS: "/organization/:organizationId/study/:studyId/sessions",
  ORGANIZATION_STUDY_PARTICIPANT_DEACTIVATE: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/deactivate",
  ORGANIZATION_STUDY_PARTICIPANT_TOGGLE_CAN_RETRY_UPLOAD_DATA_STREAM: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/data_stream/:dataStreamId/toggle_can_retry_upload",
  ORGANIZATION_STUDY_PARTICIPANT_SET_SCHEDULE_DATA_STREAM: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/data_stream/:dataStreamId/set_schedule",
  DOWNLOAD_ORGANIZATION_STUDY_DATA: "/organization/:organizationId/study/:studyId/download",
  ORGANIZATION_RECENT_UPLOADING_COUNT: "/organization/:organizationId/study/:studyId/sessions/recent_uploading_count",
  /** participant session */
  ORGANIZATION_STUDY_PARTICIPANT_DATA_STREAMS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/data_streams",
  ORGANIZATION_STUDY_PARTICIPANT_SESSION: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId",
  ORGANIZATION_STUDY_PARTICIPANT_SESSION_REAL_TIME_RESULTS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/real_time_results",
  DOWNLOAD_PARTICIPANT_SESSION_DATA: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/download",
  DOWNLOAD_STATUS: "/download/:downloadId/status",
  /** upload stats */
  SESSION_UPLOAD_STATS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/upload_stats",
  /** participant results */
  PARTICIPANT_RESULTS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/participant_results",
  PARTICIPANT_RESULTS_SET: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/participant_result",
  /** auth */
  QR_AUTH_STATUS: "/auth/display_code/status",
  GOOGLE_LOGIN: "/auth/google",
  /** notes */
  STUDY_NOTES: "/organization/:organizationId/study/:studyId/notes",
  STUDY_NOTE: "/organization/:organizationId/study/:studyId/note/:noteId",
  PARTICIPANT_PHOTO: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/participant_photo",
  PARTICIPANT_NOTES: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/notes",
  PARTICIPANT_NOTE: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/note/:noteId",
  SESSION_NOTES: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/notes",
  SESSION_NOTE: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/session/:sessionId/note/:noteId",
  /** messages */
  BATCH_MESSAGES: "/organization/:organizationId/study/:studyId/messages",
  BATCH_MESSAGES_STATUS: "/organization/:organizationId/study/:studyId/messages/status/:batchMessageId",
  PARTICIPANT_MESSAGES: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/messages",
  PARTICIPANT_MESSAGE_VIEWED: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/message/:messageId/viewed",
  PARTICIPANT_MESSAGES_VIEW: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/messages/view",
  PARTICIPANT_TOGGLE_NEEDS_ATTENTION: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/messages/notification",
  /** message templates */
  STUDY_MESSAGE_TEMPLATES: "/organization/:organizationId/study/:studyId/message/templates",
  STUDY_MESSAGE_TEMPLATE: "/organization/:organizationId/study/:studyId/message/template/:messageTemplateId",
  /** docusign */
  DOCUSIGN: "/organization/:organizationId/study/:studyId/docusign",
  DOCUSIGN_CONSENT: "/organization/:organizationId/study/:studyId/docusign/consent",
  DOCUSIGN_TEMPLATES: "/organization/:organizationId/study/:studyId/docusign/templates",
  DOCUSIGN_DOCUMENT_TEMPLATE: "/organization/:organizationId/study/:studyId/docusign/template/:document",
  DOCUSIGN_DOCUMENT_SEND: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/docusign/send/:document",
  DOCUSIGN_DOCUMENT_STATUS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/docusign/status/:document",
  /** reports */
  STUDY_SESSION_REPORTS: "/organization/:organizationId/study/:studyId/sessions/data",
  PARTICIPANT_SURVEY_REPORTS: "/organization/:organizationId/study/:studyId/participants/data",
  PARTICIPANT_FUNNEL_REPORTS: "/organization/:organizationId/study/:studyId/status_counts_charts",
  /** scheduling */
  CALENDAR_STUDY_EVENTS: "/organization/:organizationId/study/:studyId/calendar",
  CALENDAR_PARTICIPANT_EVENTS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/calendar",
  /** participant ratings */
  PARTICIPANT_RATE: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/rate",
  PARTICIPANT_HISTORICAL_RATING: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/historical_rating",
  PARTICIPANT_HISTORICAL_AVG_RATING: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/historical_average_rating",
  /** payments */
  PAY: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/pay",
  PAYMENT_HISTORY: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/payment_history",
  PAYMENT_CANCEL: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/payment/:paymentHistoryId",
  /** study reports */
  STUDY_REPORTS: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/study_reports",
  STUDY_REPORT: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/study_report/:document",
  /** clinics */
  CLINIC: "/clinic/:studyId",
  CLINIC_AUTH: "/clinic/:studyId/auth",
  CLINIC_REGISTER: "/clinic/:studyId/register",
  CLINIC_VISIT: "/clinic/:studyId/visit",
  CLINIC_FLOW_UI: "/clinic/:studyId/flow_ui",
  CLINIC_CHECKLIST: "/clinic/:studyId/visit/checklist/:checklistLogId",
  CLINIC_CHECKLIST_MISSED: "/clinic/:studyId/visit/checklist/:checklistLogId/missed",
  CLINIC_SURVEY: "/clinic/:studyId/visit/checklist/:checklistLogId/survey/:stepId",
  CLINIC_NEXT_TASK: "/clinic/:studyId/visit/checklist/:checklistLogId/next_task/:stepId",
  CLINIC_SESSION: "/clinic/:studyId/visit/session/:virtualUserId",
  CLINIC_UPDATE: "/clinic/:studyId/update/:dataStreamId",
  CLINIC_RECENT_UPLOADING_COUNT: "/clinic/:studyId/sessions/recent_uploading_count",
  
  ENROLL: "/survey/enroll/:studyId",
  SURVEY: "/survey/:token",
  SURVEY_TOKEN: "/organization/:organizationId/study/:studyId/participant/:virtualUserId/data_stream/:dataStreamId/survey-token",
  CLINIC_SURVEY_TOKEN: "/organization/:organizationId/study/:studyId/checklist/log/:checklistLogId/survey-token/:stepId",

  ORGANIZATION_STUDY_INBOX: "/organization/:organizationId/study/:studyId/inbox",
  /** firmware/kortex update */
  UPDATE: "/update/:dataStreamId",
}
