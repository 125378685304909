import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParticipants as useParticipants, generateInvite as putGenerateInvite, ParticipantListItem, useParticipant, ParticipantStatus, useParticipantsExport, useParticipantsImport, useAddBatchMessage, ParticipantsImport, useMessageTemplates, ParticipantFilter, ICFStatusMapping, Message, useBatchMessageStatus } from '../../api'
import { Typography, Button, Form, Input, Space, Tooltip, Popover, Select, Steps, Upload, Alert, Modal, Tabs, Spin, Table, Row, InputRef, App, Card, List, Progress } from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useParams, Link, generatePath } from "react-router-dom";
import { CheckSquareOutlined, CloseSquareOutlined, DollarCircleOutlined, DollarCircleFilled, PlusCircleOutlined, CopyOutlined, MailOutlined, TableOutlined, ExportOutlined, SearchOutlined, CheckCircleOutlined, FileTextOutlined, FileTextFilled, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { copyHtmlToClipboard } from '../../utilities'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ParticipantPage from '../../pages/Participant'
import moment from 'moment'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { Loader } from '../Loader'
import { useForm } from 'antd/lib/form/Form';
import CustomMDEditor from '../CustomMarkdownEditor';
import StudyInbox from '../StudyInbox';
import styles from './styles.module.css';
import { color } from '../../theme';
import { captureException } from '@sentry/react';

const { Title, Paragraph, Text } = Typography
const { Option } = Select
const { Step } = Steps

type GenerateInviteProps = {
  organizationId: string;
  studyId: string;
  studyName?: string;
}

export const buildParticipantInvitation = (studyName: string, participantId: string, inviteCode: string): string => `
<p>You have been invited to participate in the <strong>${studyName}</strong> study as participant <strong>${participantId}</strong>.<br />
  <br />
  To get started, please download the Kernel mobile app:
  <ul>
    <li><a href="https://apps.apple.com/us/app/kernel-app/id1534622186">App Store: iOS</a></li>
    <li><a href="https://play.google.com/store/apps/details?id=com.kernel.Kernel">Google Play: Android</a></li>
  </ul>
  <br />
  Sign up, and use the following invite code to join the study:
  <code>${inviteCode}</code>
</p>
`

const GenerateInvite: React.FC<GenerateInviteProps> = ({
  organizationId,
  studyId,
  studyName,
}: GenerateInviteProps) => {
  const [form] = Form.useForm()
  const { message: messageApi } = App.useApp();
  const { study } = useSelectedStudyContext()
  const [generateInvite, { data, isLoading: generateInviteLoading, error: generateInviteError }] = putGenerateInvite({
    onError: () => {
      void messageApi.error('Failed to generate study invitation')
    }
  })
  
  const inviteFormFinishHandler = useCallback(async ({ participant_id }: Record<string, string>) => {
    await generateInvite({
      organizationId,
      studyId,
      participant_id
    })
    form.resetFields()
  }, [generateInvite, organizationId, studyId, form])

  const invitation = useMemo(() => {
    if (data?.invite_id) {
      return buildParticipantInvitation(studyName as string, data.participant_id, data.invite_id)
    }
  }, [data?.invite_id])

  const copyInviteCodeToClipboard = useCallback(async () => {
    const inviteCode = data?.invite_id

    if (inviteCode) {
      try {
        await navigator.clipboard.writeText(inviteCode)
        void messageApi.success('Copied to Clipboard!')
      } catch {
        void messageApi.error('Failed to Copy')
      }
    }
  }, [data])

  const copyInvitationToClipboard = useCallback(() => {
    if (invitation) {
      try {
        copyHtmlToClipboard(invitation)
        void messageApi.success('Copied to Clipboard!')
      } catch (error) {
        captureException(error)
        void messageApi.error('Failed to Copy')
      }
    }
  }, [invitation])

  return (
    <>
      <Form
        name="generate-invite"
        layout={'inline'}
        form={form}
        onFinish={inviteFormFinishHandler}
        size="small"
      >
        <Tooltip title="ex: KE084 or leave blank to autogenerate">
          <Form.Item label="Add ID" name="participant_id">
            <Input />
          </Form.Item>
        </Tooltip>
        <Form.Item style={{ marginRight: 0, width: 'unset' }}>
          <Tooltip title='Generate Invite' placement="bottomRight" arrow={{ pointAtCenter: true }}>
            <Popover
              title={generateInviteLoading ? 'Generating Invite...' : data ? `Copy ${data.participant_id}'s Invite` : 'Invite'}
              trigger="click"
              open={
                // `open` shouldn't be needed but `trigger="click"` opens then closes fast in the new antd version so we want it to stay open
                // TODO: re-evaluate after we upgrade antd again
                study?._permissions.invite_code_enabled && (generateInviteLoading || !!generateInviteError || !!data)
              }
              content={(
                <Space style={{ width: '100%', justifyContent: 'center', padding: '1rem' }}>
                  {
                    generateInviteLoading ? <Loader /> :
                      generateInviteError ? <Alert type="error" showIcon message="Error Generating Invite" /> :
                        data?.invite_id && (
                          <Space>
                            <Button icon={<CopyOutlined style={{ color: color.blue }} />} onClick={copyInviteCodeToClipboard}>
                              Copy Invite Code
                            </Button>

                            <Button icon={<MailOutlined style={{ color: color.blue }} />} onClick={copyInvitationToClipboard}>
                              Copy Invitation
                            </Button>
                          </Space>
                        )
                  }
                </Space>
              )}
            >
              <Button style={{ border: 'none', background: 'none', padding: '0', width: 'unset' }} htmlType="submit" disabled={generateInviteLoading} icon={<PlusCircleOutlined style={{ color: color.blue }} />} onClick={() => form.submit()} />
            </Popover>
          </Tooltip>
        </Form.Item>
      </Form>
    </>
  )
}


export const Participants: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [participantFilter, setParticipantFilter] = useState<ParticipantFilter>({ status: "" });
  const { isLoading: participantsLoading, isError: participantsError, data: participants } = useParticipants(organizationId, studyId, participantFilter);

  const { study } = useSelectedStudyContext()

  const { path } = useRouteMatch()
  const match = useRouteMatch<{ virtualUserId?: string }>('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId');
  const participantVirtualUserId = match?.params.virtualUserId;
  const { data: participant } = useParticipant(organizationId, studyId, participantVirtualUserId);

  const allParticipantsRoute = {
    title: <Link to={generatePath('/organizations/:organizationId/studies/:studyId/participants', { organizationId, studyId })}>All Participants</Link>,
  }

  // there routes build the breadcrumb for the nested study participants router
  const [routes, setRoutes] = useState([allParticipantsRoute])

  // derive the `routes` state from URL changes
  useEffect(() => {
    setRoutes([
      allParticipantsRoute,
      ...participantVirtualUserId && participant?.participant_id ? [{
        title: <>{participant.participant_id}</>
      }] : []
    ])

  }, [participantVirtualUserId, participant?.participant_id])

  // Batch export/form
  const [batchEditParticipantsVisible, setBatchEditParticipantsVisible] = useState<boolean>(false)
  const [exportStep, setExportStep] = useState<number>(0);
  const [batchMessageType, setBatchMessageType] = useState<Message['message_type']>("email");
  const [batchMessageModal, setBatchMessageModal] = useState<boolean>();
  const [batchMessageForm] = useForm();
  const [newBody, setNewBody] = useState<string>("");
  const [participantsImportResult, setParticipantsImportResult] = useState<ParticipantsImport>()
  const [activeTab, setActiveTab] = useState("Participants");
  const { message: messageApi } = App.useApp();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [batchMessageId, setBatchMessageId] = useState<string | undefined>(undefined);

  const nameSearchInput = useRef<InputRef>(null);

  const { data: messageTemplates, isLoading: messagesTemplatesLoading } = useMessageTemplates(organizationId, studyId, !!study?._permissions.can_message)
  const [participantsExport, { isLoading: participantsExportLoading }] = useParticipantsExport({
    onError: () => {
      void messageApi.error("Failed to export participants")
    }
  })

  const [participantsImport, { isLoading: participantsImportLoading }] = useParticipantsImport({
    onError: () => {
      void messageApi.error("Failed to import participants")
    }
  })

  const [sendBatchMessage] = useAddBatchMessage({
    onError: () => {
      void messageApi.error("Failed to send batch messages")
    },
    onSuccess: () => {
      void messageApi.info(`Sending batch messages ...`)
      batchMessageForm.resetFields()
      setBatchMessageModal(false)
      setNewBody("");
    }
  })

  const { data: batchMessageStatus } = useBatchMessageStatus(organizationId, studyId, batchMessageId)

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters) {
      const filterOptions = Object.keys(filters).reduce((result: { [key: string]: string }, key) => {
        const value = filters[key];

        if (value && Array.isArray(value))
          result[key] = (value[0] as string).toLowerCase();
        else result[key] = value || "";

        return result;
      }, {});

      setParticipantFilter(prev => ({ ...prev, ...filterOptions }));
    }
  };

  const columns: ColumnsType<ParticipantListItem> = useMemo(() => {
    const result: ColumnsType<ParticipantListItem> = [];

    if (study?._permissions.can_view_participant_status) {
      result.push(
        {
          title: (
            <Tooltip title="Needs Addressing">
              <WarningOutlined />
            </Tooltip>
          ),
          dataIndex: ['virtual_user', 'requires_attention'],
          key: 'needs_addressing',
          render: function NeedsAddressing(_, participant) {
            return  <Tooltip title={participant.requires_attention ? 'Needs addressing' : 'Nothing to address'}>
                      {participant.requires_attention ? <WarningOutlined /> : <CheckCircleOutlined />}
                    </Tooltip>;
          },
          sorter: (a, b) => Number(a.requires_attention) - Number(b.requires_attention),
          width: 75,
          showSorterTooltip: false,
        }
      )
    }

    result.push({
        title: 'Participant',
        dataIndex: ['virtual_user', 'participant_id'],
        key: 'participant_id',
        width: 200,
        sorter: (a, b) => a.participant_id.localeCompare(b.participant_id),
        filterDropdown: function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters })  {
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={nameSearchInput}
                placeholder={`Search by Participant Id`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )
        },
        filterIcon: function FilterIcon(filtered) {
          return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        },
        onFilterDropdownOpenChange: visible => {
          if (visible) {
            setTimeout(() => nameSearchInput.current?.select(), 100);
          }
        },
        render: function ParticipantId(_, participant) {
          return (
            <Row 
              align='middle'
              className={styles.ParticipantIdCell}
            >
              <Text>
                <Link to={generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId', {
                  organizationId,
                  studyId,
                  virtualUserId: participant.id
                })}>
                  {participant.participant_id}
                </Link>
                <br />
                {
                  study?._permissions.can_rate &&
                    <Text>Rating: {participant.rating || "N/A"}</Text>
                }
              </Text>
            </Row>
          )
        },
        ellipsis: true,
    });

    if (study?._permissions.can_view_participant_status) {
      result.push({
        title: 'Status',
        dataIndex: ['virtual_user', 'status'],
        key: 'status',
        width: 150,
        sorter: (a, b) => a.status.localeCompare(b.status),
        filters: Object.entries(ParticipantStatus).map(([status, { text }]) => ({
          text,
          value: status
        })),
        filterMultiple: false,
        render: function Status(_, participant) {
          return (
            <div>
              <div className={styles.CirlceIndicator} style={{ display: 'inline-block', background: ParticipantStatus[participant.status].hex }} />
              <Text>{ParticipantStatus[participant.status].text}</Text> <br />
              <Text style={{ fontSize: 12 }}>{moment.unix(participant.created_at).format("MM/DD/YYYY")}</Text>
            </div>
          )
        }
      }) 
    }

    result.push({
      title:(
        <Tooltip title="Pending">
          <MailOutlined />
        </Tooltip>
      ),
      dataIndex: 'pending',
      key: 'pending',
      sorter: (a, b) => Number(a.pending) - Number(b.pending),
      width: 50,
      showSorterTooltip: false,
      render: function Pending(_, participant) {
        return participant.pending
          ? <Tooltip key="unaccepted-invite" title="Participant has not Joined. Navigate to this participant's page to generate a new invitation." arrow={{ pointAtCenter: true }}>
              <MailOutlined />
            </Tooltip>
          : <Tooltip key="accepted-invite" title="Participant has Joined." arrow={{ pointAtCenter: true }}>
              <CheckCircleOutlined />
            </Tooltip>
      }
    });

    if (study?._permissions.can_view_participant_status) {
      result.push({
          title: (
            <Tooltip title="W9 Status">
              <DollarCircleOutlined />
            </Tooltip>
          ),
          sorter: true,
          key: 'w9_status',
          dataIndex: 'w9_status',
          width: 80,
          showSorterTooltip: false,
          render: function W9(_, participant) {
            return (
              <Tooltip overlay={participant.w9_status ? "W9 complete" : "W9 not complete"}>
                {participant.w9_status ? <DollarCircleFilled /> : <DollarCircleOutlined />}
              </Tooltip>
            )
          }
        },
        {
          title: (
            <Tooltip title="ICF Status">
              <FileTextOutlined />
            </Tooltip>
          ),
          sorter: true,
          key: 'icf_status',
          dataIndex: 'icf_status',
          width: 80,
          filters: Object.entries(ICFStatusMapping).map(([status, text]) => ({
            text,
            value: status
          })),
          filterMultiple: false,
          showSorterTooltip: false,
          render: function ICF(_, participant) {
            return (
              <Tooltip overlay={participant.icf_status && ICFStatusMapping[participant.icf_status] ? ICFStatusMapping[participant.icf_status] : "ICF not sent"}>
                {participant.icf_status === "completed" ? <FileTextFilled /> : <FileTextOutlined />}
              </Tooltip>
            )
          }
        })
    }

    if (study?._permissions.can_view_checklists) {
      result.push({
        title: (
          <Tooltip title="Completed Checklists">
            <CheckSquareOutlined />
          </Tooltip>
        ),
        key: 'checklists_completed',
        sorter: true,
        dataIndex: 'checklists_complete',
        width: 50,
        showSorterTooltip: false,
      })

      result.push({
        title: (
          <Tooltip title="Incomplete Checklists">
            <CloseSquareOutlined />
          </Tooltip>
        ),
        sorter: true,
        key: 'checklists_partially_complete',
        dataIndex: 'checklists_partially_complete',
        width: 50,
        showSorterTooltip: false,
      })
    }

    if (study?._permissions.can_message) {
      result.push({
        title: "Messaging",
        dataIndex: ['message', 'body'],
        key: "message",
        render: function Message(_, participant) {
          if(!participant.message) return null;

          return (
            <Row
              className={styles.MessageCell}
              style={{ fontWeight: participant.messages_require_attention ? 'bold' : 'normal' }} 
              align="top" 
              wrap={false}
            >
              {participant.messages_require_attention && <div className={styles.CirlceIndicator} />}
              <div className={styles.FullWidth}>
                <Row justify='space-between' style={{ paddingRight: 2 }}>
                  <Link to={
                    generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId/messages', {
                      organizationId,
                      studyId,
                      virtualUserId: participant.id
                    })
                  }>
                    {participant.message.sender || "Participant"}
                  </Link> 
                  <Text>{moment.unix(participant.message.created_at).format('MMM DD LT')}</Text> 
                </Row>

                <Text style={{ fontSize: 12, width: '100%' }} ellipsis={true}>{participant.message?.body}</Text>
              </div>
            </Row>
          )
        }
      })
    }

    return result;
  }, [study])

  const batchMessageHeader = useMemo(() => {
    if (participantFilter.status === "" && !participantFilter.participant_id && selectedRowKeys.length === 0) {
      return "Send Batch Message to All Participants";
    }

    if (selectedRowKeys.length > 0) {
      return `Send Batch Message to ${selectedRowKeys.length} Participant(s)`;
    }

    if (participantFilter.status) {
      return `Send Batch Message to ${ParticipantStatus[participantFilter.status].text} Participants`;
    }
  }, [participantFilter, selectedRowKeys]);

  const handleBatchMessageFormSubmit = async () => {
    try {
      await batchMessageForm.validateFields();
      const res = await sendBatchMessage({
        organizationId,
        studyId,
        selectedParticipantIds: selectedRowKeys as string[],
        status: participantFilter.status,
        message: { message_type: batchMessageType, body: newBody }
      });
      batchMessageForm.resetFields();
      if (res?.success) setBatchMessageId(res.batch_message_id)
    } catch (error) {
      captureException(error);
      void messageApi.error("Error batch messaging!")
    }
  }

  return (
    <>
      <PageHeader
        breadcrumb={{ items: routes }}
        style={{ paddingTop: 0 }}
        {...!participantVirtualUserId && {
          title: (
            <Space direction="vertical">
              <Space align="center" size="middle">
                <Title level={3} style={{ margin: 0 }}>Participants</Title>
                {activeTab === "Participants" && study?._permissions.can_manage_participant_status && (
                  <>
                    <Modal open={batchEditParticipantsVisible} title={<Title level={4}>Batch Edit Participant Status</Title>} width={600} closable={false} footer={[
                      <Button key="submit" type="primary" onClick={() => setBatchEditParticipantsVisible(false)}>
                        Ok
                      </Button>,
                    ]}>
                      <Steps direction="vertical" current={exportStep}>
                        <Step title="Export" description={(
                          <Button disabled={participantsExportLoading} loading={participantsExportLoading} icon={<ExportOutlined />} onClick={async () => {
                            await participantsExport({ organizationId, studyId })
                            setExportStep(1)
                          }}>
                            Export your file to CSV
                          </Button>
                        )} />
                        <Step title="Edit" description={
                          <Paragraph>
                            For any participant whose status you want updated, fill in the<br />
                            <Text code>new_status</Text> and optionally the <Text code>new_status_reason</Text> field<br />
                            with the desired new values. Valid statuses include:<br />
                            {Object.keys(ParticipantStatus).map((key, i) => <Text code key={key}>{key}{(i + 1) % 4 === 0 && <br />}</Text>)}
                          </Paragraph>
                        } />
                        <Step title="Import" description={
                          <Space direction='vertical'>
                            <Paragraph>
                              Save the file and <Upload
                                disabled={participantsImportLoading}
                                accept=".csv"
                                showUploadList={false}
                                beforeUpload={file => {
                                  if (file) {
                                    void (async () => {
                                      const participantsImportResult = await participantsImport({ organizationId, studyId, file });
                                      if (participantsImportResult) {
                                        setParticipantsImportResult(participantsImportResult)
                                        setExportStep(2)
                                      }
                                    })();
                                  }
                                  return false
                                }}
                              >
                                <Tooltip title="Upload CSV">
                                  <Button type="primary" loading={participantsImportLoading} disabled={participantsImportLoading}>
                                    Upload
                                  </Button>
                                </Tooltip>
                              </Upload>
                            </Paragraph>
                            {participantsImportResult && <Alert type='info' message={[
                              ...(participantsImportResult.updated > 0 ? [`${participantsImportResult.updated} Updated`] : []),
                              ...(participantsImportResult.not_updated > 0 ? [`${participantsImportResult.not_updated} Not Updated`] : []),
                              ...(participantsImportResult.stale > 0 ? [`${participantsImportResult.stale} Stale`] : []),
                              ...(participantsImportResult.not_found > 0 ? [`${participantsImportResult.not_found} Not Found`] : []),
                              ...(participantsImportResult.error > 0 ? [`${participantsImportResult.error} Error`] : []),
                            ].join(', ')} />}
                          </Space>
                        } />
                      </Steps>
                    </Modal>
                    <Tooltip title="Batch Edit Participant Status">
                      <Button icon={<TableOutlined />} onClick={() => setBatchEditParticipantsVisible(true)} />
                    </Tooltip>
                  </>
                )}
                {
                  activeTab === "Participants" && study?._permissions.can_manage_participant_status && <Button disabled={participants?.participants?.length === 0 || !!participantFilter?.participant_id} onClick={() => setBatchMessageModal(true)}>Send Batch Message</Button>
                }
                <Modal
                  width={"1000px"}
                  title={batchMessageHeader}
                  open={batchMessageModal}
                  onCancel={() => {
                    setBatchMessageModal(false);
                    setNewBody("");
                    batchMessageForm.resetFields();
                  }}
                  onOk={() => handleBatchMessageFormSubmit()}
                  okText="Send">
                  <Form form={batchMessageForm}>
                    <Form.Item label="Type">
                      <Select value={batchMessageType} style={{ width: 200 }} onChange={setBatchMessageType}>
                        <Option value="chat">Chat</Option>
                        <Option value="email">Email</Option>
                        <Option value="sms">SMS</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Body">
                      <CustomMDEditor height={200} value={newBody} onChange={setNewBody} autofillCommands="study" />
                    </Form.Item>
                    <Form.Item label="Use Template">
                      {messagesTemplatesLoading || !messageTemplates ? (
                        <Spin />
                      ) : (
                        <Select defaultValue={""} style={{ width: 200 }} onChange={(value) => {
                          const messageTemplate = messageTemplates.find(messageTemplate => messageTemplate.id === value);
                          if (messageTemplate) {
                            setNewBody(messageTemplate.body)
                          } else {
                            setNewBody("")
                          }
                        }}>
                          <Option value="">None</Option>
                          {messageTemplates.map((messageTemplate) => (
                            <Option key={messageTemplate.id} value={messageTemplate.id}>{messageTemplate.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Form>
                </Modal>
              </Space>
              {participantsError && <Alert type="error" showIcon message="Error loading participants!" />}
            </Space>
          ),
          extra: activeTab === "Participants" && study?._permissions.can_invite_participant && [
            <GenerateInvite key="add-participant" organizationId={organizationId} studyId={studyId} studyName={study?.name} />
          ]
        }}
      >
        <Switch>
          <Route path={path} exact={true}>
            <Tabs tabPosition="left" style={{ paddingTop: 10 }} size="large" onChange={setActiveTab}
              items={[
                { key: "Participants", label: "Participants", children: (
                  <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {batchMessageStatus && (
                      <Card title="Batch Message Results" extra={
                          <div style={{ width: 200 }}>
                            <Progress
                              percent={Math.ceil(100.0 * batchMessageStatus.completed / batchMessageStatus.total)}
                              format={() => `${batchMessageStatus.completed} / ${batchMessageStatus.total}`}
                            />
                          </div>
                      }>
                        <Alert type="warning" showIcon message="If you navigate away, you will lose these results!" />
                        {batchMessageStatus.success.length > 0 && (
                          <List
                            size="small"
                            dataSource={batchMessageStatus.success}
                            renderItem={item => (
                              <List.Item>
                                <Space direction="horizontal" size="small">
                                  <CheckCircleOutlined style={{ color: color.green }} />
                                  {item}
                                </Space>
                              </List.Item>
                            )}
                          />
                        )}
                        {Object.keys(batchMessageStatus.failed).length > 0 && (
                          <List
                            size="small"
                            dataSource={Object.entries(batchMessageStatus.failed).map(([k, v]) => ({ participantId: k, error: v }))}
                            renderItem={item => (
                              <List.Item>
                                <Space direction="horizontal" size="small">
                                  <ExclamationCircleOutlined style={{ color: color.red }} />
                                  {item.participantId} : {item.error}
                                </Space>
                              </List.Item>
                            )}
                          />
                        )}
                      </Card>
                    )}
                    <Table 
                      columns={columns} 
                      rowKey={record => record.id} 
                      dataSource={participants?.participants} 
                      onChange={handleTableChange} 
                      loading={participantsLoading}
                      pagination={{ pageSize: 50 }}
                      rowSelection={study?._permissions.can_manage_participant_status ? {
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                      } : undefined}
                    />
                  </Space>
                ) },
                ...(study?._permissions.can_message ? [{ key: "Messages", label: "Messages", children: <StudyInbox /> }] : []),
              ]}
            />
          </Route>

          <Route path={`${path}/:virtualUserId/:activeTab?`}>
            <ParticipantPage />
          </Route>
        </Switch>
      </PageHeader>
    </>
  )
}

