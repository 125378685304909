import React, { useState, useMemo, useCallback, Key, useEffect } from 'react'
import { Link, useParams, generatePath, useHistory } from "react-router-dom";
import {
  studyParticipantToggleRetakeDataStream as putStudyParticipantToggleRetakeDataStream,
  studyParticipantSetScheduleDataStream as putStudyParticipantSetScheduleDataStream,
  useParticipantDataStreams,
  useParticipant,
  useUpdateParticipant,
  DataStreamStatus,
  DataStream,
  useGenerateReinvite,
  ParticipantStatus,
  StudySurveyAnswer,
  DataStreamSurveyStep,
  useDocuSignStatus,
  useDocuSignSend,
  useSyncDocuSignStatus,
  PaymentHistory,
  usePaymentHistory,
  useSendPayment,
  PaymentDetails,
  TransactionTypes as TransactionType,
  useCancelPayment,
  useChecklist,
  ChecklistLog,
  useChecklistLogs,
  useUpdateChecklistLog,
  ChecklistLogData,
  ChecklistLogValues,
  DataStreamSurvey,
  useSetChecklistLogMissed,
  useSurveyToken,
  useClinicSurveyToken,
  useApplyProtocol,
} from '../../api'
import {
  Tag,
  Typography,
  Space,
  Tooltip,
  Popconfirm,
  App,
  Button,
  Table,
  Modal,
  Popover,
  Form,
  Input,
  Select,
  Card,
  Tabs,
  Descriptions,
  Spin,
  Empty,
  Col,
  Row,
  InputNumber,
  Alert,
  Collapse,
  Checkbox,
  Divider,
  DatePicker,
  QRCode,
} from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import {
  UndoOutlined,
  RedoOutlined,
  EditOutlined,
  StopOutlined,
  FieldTimeOutlined,
  DeleteOutlined,
  CalendarOutlined,
  CopyOutlined,
  MailOutlined,
  FilterOutlined,
  SyncOutlined,
  FormOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import { buildParticipantInvitation } from '../../components/StudyParticipants'
import { copyHtmlToClipboard, downloadFileUrl, redirectPortalToResearch } from '../../utilities'
import { Loader, LoadingIndicator } from '../../components/Loader'
import styles from './styles.module.css'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy';
import Notes from '../../components/Notes';
import { Ratings } from '../../components/ParticipantRatings';
import { StudyParticipantReports } from '../../components/StudyParticipantReports';
import { Messages } from './Messages';
import { color } from '../../theme';
import dayjs from 'dayjs'
import { captureException } from '@sentry/react';

const {
  Title,
  Text,
  Paragraph,
  Link: AntLink,
} = Typography

const { Option } = Select
const { TextArea } = Input

const StatusHistory: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: participant, isLoading: participantLoading } = useParticipant(organizationId, studyId, virtualUserId)
  const [form] = Form.useForm()

  const [newStatus, setNewStatus] = useState<string>()
  const [newStatusReason, setNewStatusReason] = useState<string>()
  const { message: messageApi } = App.useApp();

  const [updateParticipant, {
    isLoading: updateParticipantLoading
  }] = useUpdateParticipant({
    onError: () => {
      void messageApi.error('Failed to create status')
    },
    onSuccess: () => {
      void messageApi.success('Successfully created status')
      setNewStatus("")
      setNewStatusReason("")
    }
  })

  const columns: ColumnsType<{ created_at: number, created_by_user_email: string, status: string, status_reason: string }> = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: function Datetime(created_at) {
        return <Text style={{ whiteSpace: 'nowrap' }}>{moment.unix(created_at).format('MMM DD LT')}</Text>
      }
    },
    {
      title: 'Researcher',
      dataIndex: 'created_by_user_email',
      key: 'researcher',
      render: function Researcher(created_by_user_email: string) {
        return (
          <Tooltip title={created_by_user_email}>
            <Text>{created_by_user_email.split('@')[0]}</Text>
          </Tooltip>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: function Status(status: keyof typeof ParticipantStatus) {
        return <Tag color={ParticipantStatus[status].color}>{ParticipantStatus[status].text}</Tag>
      }
    },
    {
      title: 'Reason',
      dataIndex: 'status_reason',
      key: 'status_reason',
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Title level={3} style={{ margin: 0 }}>Status History</Title>

      <Table
        pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
        {...(participantLoading || updateParticipantLoading) && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={participant?.statuses}
        columns={columns}
        rowKey="id"
      />

      {participant?._permissions.can_manage_status && (
        <Card title="Set New Status">
          <Form form={form} onFinish={() => updateParticipant({ organizationId, studyId, virtualUserId, status: newStatus, status_reason: newStatusReason })}>
            <Form.Item label="Status" required={true}>
              <Select value={newStatus} style={{ width: 200 }} onChange={(value) => setNewStatus(value)}>
                <Option value="">{""}</Option>
                {Object.entries(ParticipantStatus).map(([status, { text }]) => (
                  <Option key={status} value={status}>{text}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Reason">
              <Input onChange={({ target }) => setNewStatusReason(target.value)} value={newStatusReason} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" disabled={updateParticipantLoading || !newStatus}>Submit</Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </Space>
  )
}

const ReinviteParticipant: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: participant } = useParticipant(organizationId, studyId, virtualUserId)

  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const [generateReinvite, { data: reinvitation, isLoading: generateReinviteLoading, error: generateReinviteError }] = useGenerateReinvite()

  const invitation = useMemo(() => {
    if (study?.name && reinvitation?.invite_id && participant?.participant_id) {
      return buildParticipantInvitation(study?.name, participant.participant_id, reinvitation.invite_id)
    }
  }, [study?.name, reinvitation?.invite_id, participant?.participant_id])

  const copyInviteCodeToClipboard = useCallback(async () => {
    const inviteCode = reinvitation?.invite_id

    if (inviteCode) {
      try {
        await navigator.clipboard.writeText(inviteCode)
        void messageApi.success('Copied to Clipboard!')
      } catch {
        void messageApi.error('Failed to Copy')
      }
    }
  }, [reinvitation])

  const copyInvitationToClipboard = useCallback(() => {
    if (invitation) {
      try {
        copyHtmlToClipboard(invitation)
        void messageApi.success('Copied to Clipboard!')
      } catch (error) {
        captureException(error)
        void messageApi.error('Failed to Copy')
      }
    }
  }, [invitation])

  return participant ? (
    <Space size="small">
      {participant.pending && study?._permissions.invite_code_enabled && (
        <Tooltip title="Has not accepted invite. Click to reinvite." placement="right">
          <Popover
            title={generateReinviteLoading ? 'Regenerating Invite...' : reinvitation ? `Copy ${participant.participant_id}'s New Invite` : 'Invite'}
            trigger="click"
            content={(
              <Space style={{ width: '100%', justifyContent: 'center', padding: '1rem' }}>
                {
                  generateReinviteLoading ? <Loader /> :
                    generateReinviteError ? <Alert type="error" showIcon message="Error Regenerating Invite" /> :
                    reinvitation?.invite_id && (
                        <Space>
                          <Button icon={<CopyOutlined style={{ color: color.blue }} />} onClick={copyInviteCodeToClipboard}>
                            Copy Invite Code
                          </Button>

                          <Button icon={<MailOutlined style={{ color: color.blue }} />} onClick={copyInvitationToClipboard}>
                            Copy Invitation
                          </Button>
                        </Space>
                      )
                }
              </Space>
            )}
          >
            <Button style={{ border: 'none', background: 'none', padding: '0', width: 'unset' }} icon={<FieldTimeOutlined style={{ color: color.yellow, fontSize: 16 }} />} onClick={() => {
              void generateReinvite({
                organizationId,
                studyId,
                virtualUserId,
              })
            }} />
          </Popover>
        </Tooltip>
      )}
    </Space>
  ) : null
}

const DataStreamStatusSchedule: React.FC<{ id: string, dataStreamStatus?: DataStreamStatus, name: string }> = ({ name, id, dataStreamStatus }) => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { message: messageApi } = App.useApp();

  const [studyParticipantSetScheduleDataStream] = putStudyParticipantSetScheduleDataStream({
    onError: () => {
      void messageApi.error('Failed to set schedule!')
    },
    onSuccess: () => {
      void messageApi.success('Schedule set successfully!')
    }
  })

  const [newSchedule, setNewSchedule] = useState<number[]>(dataStreamStatus?.next_ready || []);
  const [newDate, setNewDate] = useState<number>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleModalOk = () => {
    void studyParticipantSetScheduleDataStream({
      id,
      organizationId,
      studyId,
      virtualUserId,
      nextReady: newSchedule,
    })

    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    // if cancelling, revert local state to stream status
    setNewSchedule(dataStreamStatus?.next_ready ?? [])
  }

  // ready dates from the current data stream (unedited schedules)
  const dataStreamSchedules = dataStreamStatus?.next_ready ?? []
  const { data: participant } = useParticipant(organizationId, studyId, virtualUserId)

  // Manage selected columns in the data stream schedule / event data table
  const [selectedEventRowKeys, setEventRowKeys] = useState<Key[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<Set<number>>(new Set());
  function deleteEventsFromSelected() {
    setNewSchedule(newSchedule.filter((number) => !selectedEvents?.has(number)));
    clearEventsState();
  }

  function clearEventsState() {
    setSelectedEvents(new Set());
    setEventRowKeys([]);
  }

  useEffect(() => {
    return clearEventsState();
  }, []);

  const columns: ColumnsType<{ number: number }> = [
    {
      title: 'Event Time',
      dataIndex: 'number',
      key: 'number',
      width: '100%',
      render: function Datetime(number) {
        return <Text style={{ whiteSpace: 'nowrap' }}>{moment.unix(number).format('MMM DD LT')}</Text>
      }
    },
    {
      title: <RemoveEventsHeader numToRemove={selectedEvents?.size} onRemoveClick={deleteEventsFromSelected} />,
      key: 'actions',
    }
  ];

  const [repeatDailyUntil, setRepeatDailyUntil] = useState<Date>()

  const setNewScheduleWithRepeats = useCallback(() => {
    if (newDate) {
      const days = Math.ceil(moment(repeatDailyUntil).diff(moment(newDate * 1000), 'days', true))
      const repeatedDates: number[] = repeatDailyUntil ? Array.from({ length: days }).map((_, index) => {
        return newDate + (index + 1) * 24 * 60 * 60
      }) : []

      setNewSchedule(schedule => [...schedule, newDate, ...repeatedDates])
    }
  }, [repeatDailyUntil, setNewSchedule, newDate])

  const setNewDateAndAdjustRepeats = useCallback((value?: Date) => {
    if (value) {
      const newNewDate = Math.floor(value.getTime() / 1000)
      // if there is a repeatDailyUntil set, maintain the previous interval (ex: 23rd repeating until 25th will adjust to 22nd repeating until 24th)
      if (repeatDailyUntil) {
        // days from newDate to repeatDailyUntil (moment#diff = moment#from)
        const days = Math.ceil(moment(repeatDailyUntil).diff(moment(newDate! * 1000), 'days', true)) // eslint-disable-line
        const newRepeatDailyUntil = new Date((newNewDate * 1000) + days * 24 * 60 * 60 * 1000)
        setRepeatDailyUntil(newRepeatDailyUntil)
      }

      setNewDate(newNewDate)
    } else {
      setNewDate(undefined)
      setRepeatDailyUntil(undefined)
    }
  }, [newDate, repeatDailyUntil, setRepeatDailyUntil, setNewDate])

  return (
    <>
      <Button icon={<CalendarOutlined style={{ color: color.blue }} />} onClick={() => setIsModalVisible(true)}>
        {dataStreamSchedules.length > 0 ? `${dataStreamSchedules.length} Future Events (Edit)` : 'No Future Events (Create)'}
      </Button>

      <Modal title="Update Schedule" open={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <Space direction="vertical">
          <Paragraph>
            The date-times listed below are when <strong>{participant?.participant_id}</strong> will be able to submit data from <strong>{name}</strong> again.
          </Paragraph>
          <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }} align="start">
            <Title level={3} style={{ margin: 0 }}>Events</Title>

            <Space direction="vertical">
              <Space>
                <Text>Select Event Time: </Text>

                <DatePicker
                  showTime
                  disabledDate={(current) =>
                    current && current < dayjs().endOf('day')
                  }
                  value={newDate ? dayjs.unix(newDate) : undefined}
                  onChange={(newDate) => {
                    setNewDateAndAdjustRepeats(newDate ? newDate.toDate() : undefined)
                  }}
                />
              </Space>

              <Space>
                <Text>Repeat Daily Until ({(repeatDailyUntil && newDate) ? `+${Math.ceil(moment(repeatDailyUntil).diff(moment(newDate * 1000), 'days', true))} days` : "optional"}): </Text>

                <DatePicker
                  disabled={!newDate}
                  disabledDate={(current) =>
                    current && current < dayjs.unix(newDate! + 1 * 24 * 60 * 60) // eslint-disable-line
                  }
                  value={dayjs(repeatDailyUntil)}
                  onChange={newDate => setRepeatDailyUntil(newDate?.toDate())}
                />
              </Space>

              <Button type="primary" disabled={!newDate} onClick={setNewScheduleWithRepeats}>Add Event{repeatDailyUntil ? 's' : ''}</Button>
            </Space>
          </Space>

          <Table
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedEventRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setEventRowKeys(selectedRowKeys);
                setSelectedEvents(new Set(selectedRows.map(row => row.number)));
              }
            }}
            pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
            dataSource={newSchedule.map((number, index) => ({ key: index, number }))} // map to object[] as required
            columns={columns}
            bordered={false}
            showHeader={newSchedule?.length > 0}
            size='small'
          />
        </Space>
      </Modal>
    </>
  );
}

type RemoveEventsHeaderProps = {
  numToRemove: number;
  onRemoveClick: () => void;
}

const RemoveEventsHeader: React.FC<RemoveEventsHeaderProps> = ({
  numToRemove = 0,
  onRemoveClick
}) => (
  <div className='table-row-header'>
    <div className='icon-label'>Remove {numToRemove} Events</div>
    <DeleteOutlined style={{ color: color.red }} onClick={onRemoveClick} />
  </div>
)

const SurveyToken: React.FC<{ dataStreamId: string }> = ({ dataStreamId }) => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();

  const [generateSurveyToken, { data: surveyToken, isLoading: surveyTokenLoading, error: surveyTokenError }] = useSurveyToken()

  const surveyUrl = surveyToken?.token && redirectPortalToResearch(`${window.location.origin}/survey/${surveyToken?.token}`)

  return (
    <Space size="small">
      <Tooltip title="Generate Survey Link" placement="top">
        <Popover
          title={surveyTokenLoading ? 'Generating Survey Link...' : 'Link'}
          trigger="click"
          placement="left"
          content={(
            <Space style={{ width: '100%', justifyContent: 'center', padding: '1rem' }}>
              {
                surveyTokenLoading ? <Loader /> :
                  surveyTokenError ? <Alert type="error" showIcon message="Error Generating Survey Link" /> :
                    surveyUrl && (
                      <Space direction='vertical'>
                        <Paragraph style={{ marginTop: 5, width: 200 }}>
                          <AntLink href={surveyUrl} copyable target='_blank' ellipsis>{surveyUrl}</AntLink>
                        </Paragraph>
                        <QRCode value={surveyUrl} size={200} />
                      </Space>
                    )
              }
            </Space>
          )}
        >
          <Button style={{ border: 'none', background: 'none', padding: '0', width: 'unset' }} icon={<QrcodeOutlined style={{ color: color.blue, fontSize: 16 }} />} onClick={() => {
            void generateSurveyToken({
              organizationId,
              studyId,
              virtualUserId,
              dataStreamId,
            })
          }} />
        </Popover>
      </Tooltip>
    </Space>
  )
}

const DataStreamsRowActions: React.FC<DataStream> = ({ id, name, always_ready, data_stream_status, data_transform: { integration } }) => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>()
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const canRetake = data_stream_status?.can_retry_upload
  const canRetakeDataStreams = study?._permissions.can_retake_data_streams

  const [studyParticipantToggleRetakeDataStream] = putStudyParticipantToggleRetakeDataStream({
    onError: () => {
      void messageApi.error(`Failed to toggle retake!`)
    }
  })

  if (always_ready) {
    return <Tag>Always Available</Tag>
  }

  return (
    <Space>
      {integration === "survey" && <SurveyToken dataStreamId={id} />}
      <DataStreamStatusSchedule id={id} dataStreamStatus={data_stream_status} name={name} />
      {canRetakeDataStreams && !canRetake &&
        <Popconfirm
          title={() => <Text>Are you sure you want to allow this participant to <strong>retake {name}</strong>?</Text>}
          onConfirm={async () => {
            const res = await studyParticipantToggleRetakeDataStream({ organizationId, studyId, virtualUserId, id })
            if (res?.success) {
              void messageApi.success(`Participant can now retake!`)
            } else {
              void messageApi.error(`Failed to toggle retake!`)
            }
          }}
          placement={'bottomLeft'}
          arrow={{ pointAtCenter: true }}
        >
          <Tooltip title={"Allow Participant to Retake"}>
            <UndoOutlined />
          </Tooltip>
        </Popconfirm>
      }
      {canRetakeDataStreams && canRetake &&
        <Popconfirm
          title={() => <Text>Are you sure you want to no longer allow this participant to <strong>retake {name}</strong>?</Text>}
          onConfirm={async () => {
            const res = await studyParticipantToggleRetakeDataStream({ organizationId, studyId, virtualUserId, id })
            if (res?.success) {
              void messageApi.success(`Participant can no longer retake!`)
            } else {
              void messageApi.error(`Failed to toggle retake!`)
            }
          }}
          placement={'bottomLeft'}
          arrow={{ pointAtCenter: true }}
        >
          <Tooltip title={"No Longer Allow Participant to Retake"}>
            <RedoOutlined style={{ color: color.blue }} />
          </Tooltip>
        </Popconfirm>
      }
      <Text copyable={{ text: id, tooltips: ["Copy Data Stream ID", "Copied"] }} />
    </Space>
  )
}

const DataStreams: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: studyParticipantDataStreams, error: studyParticipantDataStreamsError, isLoading: studyParticipantDataStreamsLoading } = useParticipantDataStreams(organizationId, studyId, virtualUserId)

  const columns: ColumnsType<DataStream> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '100%',
      render: function ID(text) {
        return <Text>{text}</Text>
      }
    },
    {
      title: 'Last Uploaded',
      dataIndex: ['data_stream_status', 'last_uploaded'],
      key: 'last_uploaded',
      width: '100%',
      render: function LastUploaded(text, { data_stream_status }) {
        if (!data_stream_status?.last_uploaded) return null

        return (
          <Tooltip title="Last Uploaded">
            <Text style={{ whiteSpace: 'nowrap' }}>{moment.unix(data_stream_status.last_uploaded).format('MMM DD LT')}</Text>
          </Tooltip>
        )
      }
    },
    {
      key: 'actions',
      align: "right",
      render: function Actions(text, dataStream) {
        return <DataStreamsRowActions {...dataStream} />
      }
    }
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Space direction="vertical">
        <Title level={3} style={{ marginTop: 0 }}>Data Streams</Title>
        {studyParticipantDataStreamsError && <Alert type="error" showIcon message="Error loading data streams!" />}
      </Space>
      <Table
        pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
        {...studyParticipantDataStreamsLoading && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={studyParticipantDataStreams}
        columns={columns}
        bordered={false}
        showHeader={false}
        size='small'
        rowKey="id"
      />
    </Space>
  )
}

const Documents: React.FC<{ document: string }> = ({ document }) => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: status, isLoading: statusLoading } = useDocuSignStatus(organizationId, studyId, virtualUserId, document)
  const { message: messageApi } = App.useApp();

  const [sync, {
    isLoading: syncLoading
  }] = useSyncDocuSignStatus({
    onError: () => {
      void messageApi.error(`Failed to sync ${document} document status`)
    },
    onSuccess: () => {
      void messageApi.success(`Successfully refreshed ${document} document status`)
    }
  })

  const [send, {
    isLoading: sendLoading
  }] = useDocuSignSend({
    onError: () => {
      void messageApi.error(`Failed to send ${document} document`)
    },
    onSuccess: () => {
      void messageApi.success(`Successfully sent ${document} document`)
    }
  })

  if (statusLoading || !status?.enabled) {
    return <></>
  }

  const completedUrl = status?.completed_url

  return (
    <Space direction="vertical">
      <Title level={3} style={{ marginTop: 0 }}>{document}</Title>

      <Space direction="horizontal">
        {(status?.logs || []).length === 0 && (
          <Button disabled={sendLoading} icon={<MailOutlined spin={sendLoading} />} onClick={() => send({ organizationId, studyId, virtualUserId, document })}>Send to Sign</Button>
        )}
        <Button disabled={syncLoading} icon={<SyncOutlined spin={syncLoading} />} onClick={() => sync({ organizationId, studyId, virtualUserId, document })}>Sync statuses with Docusign</Button>
        {completedUrl && (
          <Button icon={<FormOutlined />} onClick={() => downloadFileUrl(completedUrl)}>Download Completed Form</Button>
        )}
      </Space>

      <Table
        loading={statusLoading}
        size="small"
        columns={[
          {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
          },
          {
            title: 'Time',
            dataIndex: 'event_at',
            key: 'event_at',
            render: function Value(value: number) {
              return (
                <Text>{moment.unix(value).format('MMM DD LT')}</Text>
              )
            }
          },
          {
            title: 'Member',
            dataIndex: 'added_by_user_email',
            key: 'added_by_user_email',
            render: function Value(value: string) {
              return (
                <Text>{value.split('@')[0]}</Text>
              )
            }
          },
        ]}
        dataSource={status?.logs}
      />
    </Space>
  )
}

const EnrollmentSurveyAnswers: React.FC<{ answers: StudySurveyAnswer[] }> = ({ answers }) => {
  const { study } = useSelectedStudyContext()

  return !study ? <Spin /> : <EnrollmentSurveyAnswersInner title="Study Enrollment Survey Answers" survey={study.enrollment_survey} answers={answers} />
}

export const EnrollmentSurveyAnswersInner: React.FC<{ title: string, survey?: DataStreamSurvey, answers?: StudySurveyAnswer[] }> = ({ title, survey, answers }) => {
  const findQuestion = (steps: DataStreamSurveyStep[], answer: StudySurveyAnswer): string => {
    const step = steps.find(step => step.id == answer.step_id)
    if (!step) {
      return "N/A"
    }

    const question = step.questions.find(question => question.id == answer.question_id)
    if (!question) {
      return "N/A"
    }

    return question.title
  };

  return (
    <Descriptions title={title} column={1} bordered>
      {(answers || []).map(answer => (
        <Descriptions.Item key={`${answer.step_id}-${answer.question_id}`} labelStyle={{ width: '60%' }} label={
            !survey ? <Empty /> :
              findQuestion(survey.steps, answer)
        }>
          {answer.answer}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

const Payments: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: payments, isLoading: paymentsLoading } = usePaymentHistory(organizationId, studyId, virtualUserId);
  const { data: participant } = useParticipant(organizationId, studyId, virtualUserId);
  const [paymentFormModalOpen, setPaymentFormModalOpen] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<TransactionType | ''>('');
  const [paymentForm] = Form.useForm();
  const { message: messageApi } = App.useApp();

  const can_pay = participant?._permissions?.can_pay;
  const can_pay_paypal = participant?._permissions?.can_pay_paypal;
  const can_pay_venmo = participant?._permissions?.can_pay_venmo;
  const can_pay_manual = participant?._permissions?.can_pay_manual;

  const transactionTypes: Record<string, TransactionType> = {
    "venmo": 'PHONE',
    "paypal": 'EMAIL',
    "manual": 'MANUAL',
  }

  const resetForm = () => {
    paymentForm.resetFields();
    setPaymentFormModalOpen(false);
  }

  const [sendPayment, { isLoading: isLoadingSendPayment }] = useSendPayment({
    onError: () => {
      void messageApi.error('Failed to send payment')
    },
    onSuccess: () => {
      resetForm();
      void messageApi.success('Payment sent')
    }
  })

  const saveAndSendPayment = async () => {
    try {
      void sendPayment({
        organizationId,
        studyId,
        payment: await paymentForm.validateFields() as PaymentDetails,
        virtualUserId
      })
    } catch (err) {
      void messageApi.error('Please fill all required inputs')
    }
  }

  const [cancelPayment, { isLoading: isLoadingCancelPayment }] = useCancelPayment({
    onError: () => {
      void messageApi.error('Payment could not be cancelled')
    },
    onSuccess: () => {
      void messageApi.success('Payment cancelled');
    }
  })

  const sendCancelPayment = (payment_id: string) => {
    void cancelPayment({
      organizationId,
      studyId,
      virtualUserId,
      paymentHistoryId: payment_id
    })
  }
  const columns: ColumnsType<PaymentHistory> = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: function Datetime(created_at) {
        return <Text className='ParticipantPaymentColumnStyle'>{moment.unix(created_at).format('YYYY MMM DD LT')}</Text>
      },
      sorter: (a, b) => a.created_at - b.created_at
    },
    {
      title: 'Type',
      dataIndex: 'payment_type',
      key: 'payment_type',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amount',
      key: 'amount',
      render: function Amount(amount: string) {
        if (amount && amount.length > 0 && amount[0] === '.') amount = '0' + amount
        return <Text className='ParticipantPaymentColumnStyle'>${parseFloat(amount).toFixed(2)}</Text>
      },
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Created by',
      dataIndex: 'created_by_email',
      key: 'created_by_email',
      render: function Created_By(member: string) {
        return <Text className='ParticipantPaymentColumnStyle'>{member.split('@')[0]}</Text>
      },
    },
    {
      title: 'Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: function PaymentStatus(status) {
        return <Text className='ParticipantPaymentColumnStyle'>{status}</Text>
      },
      filters: [
        {
          text: 'SUCCESS',
          value: 'SUCCESS'
        },
        {
          text: 'FAILED',
          value: 'FAILED'
        },
        {
          text: 'PENDING',
          value: 'PENDING'
        },
        {
          text: 'UNCLAIMED',
          value: 'UNCLAIMED'
        },
        {
          text: 'RETURNED',
          value: 'RETURNED'
        },
        {
          text: 'ONHOLD',
          value: 'ONHOLD'
        },
        {
          text: 'BLOCKED',
          value: 'BLOCKED'
        },
        {
          text: 'REFUNDED',
          value: 'REFUNDED'
        },
        {
          text: 'REVERSED',
          value: 'REVERSED'
        }
      ],
      onFilter: (value, record) => record.payment_status === value
    },
    {
      title: 'Cancel Payment',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: function CancelPayment(id, row) {
        return (
          row.can_cancel ?
            <Tooltip title="Cancel payment" placement='bottom'>
              <Popconfirm
                title='Are you sure you want to cancel this payment?'
                onConfirm={() => sendCancelPayment(id)}

              >
                <Button loading={isLoadingCancelPayment} size="small" shape="circle" icon={<StopOutlined style={{ color: color.red }} />} />
              </Popconfirm>
            </Tooltip>
            : null
        )
      }
    }
  ]

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
          <Space direction="horizontal" size="large" align='center'>
            <Title level={3} style={{ marginTop: 0 }}>Payment History</Title>
            <Button type="primary" loading={isLoadingSendPayment} onClick={() => setPaymentFormModalOpen(true)}>Make a Payment</Button>
          </Space>
        </Space>
        <Table
          pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
          {...(paymentsLoading) && {
            loading: {
              indicator: <LoadingIndicator />
            }
          }}
          dataSource={payments}
          columns={columns}
          rowKey="id"
        />
      </Space>
      <Modal
        title="Make a payment"
        open={paymentFormModalOpen}
        maskClosable={false}
        onCancel={() => setPaymentFormModalOpen(false)} // Form cancel
        width={1200}
        footer={[
          <Popconfirm
            key={'popconfirm'}
            title="You are about to submit this payment"
            onConfirm={saveAndSendPayment}
            okText="Proceed with payment"
            cancelText="Go back"
            disabled={transactionType === ''}
          >
            <Button type="primary" key='submit' disabled={transactionType === ''} htmlType='submit'>
              Submit Payment
            </Button>
          </Popconfirm>
        ]}
      >
        <Form
          name="transaction"
          form={paymentForm}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 13 }}
        >
          <Row >
            <Col flex='350px' style={{ marginRight: "20px" }}>
              <Space direction="vertical">
                <Form.Item label="Payment Method" name="recipient_type" rules={[{ required: true, message: "Payment Method is required" }]} labelCol={{ span: 25 }} wrapperCol={{ span: 50 }}>
                  <Select
                    onSelect={type => setTransactionType(type as TransactionType)}
                    style={{ width: "200px" }}
                  >
                    <Select.Option value="">Select ...</Select.Option>
                    <Select.Option value={transactionTypes['paypal']} disabled={!can_pay_paypal}>PayPal</Select.Option>
                    <Select.Option value={transactionTypes['venmo']} disabled={!can_pay_venmo}>Venmo</Select.Option>
                    <Select.Option value={transactionTypes['manual']} disabled={!can_pay_manual}>Manual</Select.Option>
                  </Select>
                </Form.Item>
                <Alert message={
                  <ul>
                    {!can_pay && <li><b>Payment requires a signed W9</b></li>}
                    <li>Paypal requires a confirmed email</li>
                    <li>Venmo requires a confirmed phone number</li>
                    <li>If a Paypal or Venmo account does not exist under that email or phone number, the participant will be invited to create one to accept the payment.</li>
                  </ul>
                } />
              </Space>
            </Col>
            <Col flex="auto" >

              {transactionType === '' && (
                <Row justify='center' style={{ marginTop: "33px" }}>
                  <Text>Select a payment method</Text>
                </Row>
              )}

              <Space direction='vertical' size={0} style={{ width: "100%" }}>
                {transactionType === transactionTypes['paypal'] && (
                  <>
                    <Form.Item
                      name="email_subject"
                      rules={[{ required: true, message: 'Please input an email subject!' }]}
                      label="Subject"
                    >
                      <Input placeholder="Compensation for Study"></Input>
                    </Form.Item>
                    <Form.Item
                      name="email_message"
                      rules={[{ required: true, message: 'Please include a message!' }]}
                      label="Message"
                    >
                      <Input.TextArea placeholder="Thank you for parcipating in the study!" />
                    </Form.Item>
                  </>
                )}
                {transactionType !== '' && (
                  <>
                    <Form.Item
                      name="amount"
                      rules={[{ required: true, message: 'Please input a compensation amount! Must be greater than $1.' }]}
                      label="Amount ($)"
                    >
                      <InputNumber min='1' precision={2} step="0.01" placeholder="25.99"></InputNumber>
                    </Form.Item>
                    <Form.Item
                      name="note"
                      rules={[{ required: true, message: 'Please input a note for other members!' }]}
                      label="Internal Note"
                    >
                      <Input placeholder="Compensation for study on March 3, 2022"></Input>
                    </Form.Item>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const ClinicSurveyToken: React.FC<{ checklistLogId: string, stepId: string }> = ({ checklistLogId, stepId }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();

  const [generateSurveyToken, { data: surveyToken, isLoading: surveyTokenLoading, error: surveyTokenError }] = useClinicSurveyToken()

  const surveyUrl = surveyToken?.token && redirectPortalToResearch(`${window.location.origin}/survey/${surveyToken?.token}`)

  return (
    <Space size="small">
      <Tooltip title="Generate Survey Link" placement="top">
        <Popover
          title={surveyTokenLoading ? 'Generating Survey Link...' : 'Link'}
          trigger="click"
          placement='right'
          content={(
            <Space style={{ width: '100%', justifyContent: 'center', padding: '1rem' }}>
              {
                surveyTokenLoading ? <Loader /> :
                  surveyTokenError ? <Alert type="error" showIcon message="Error Generating Survey Link" /> :
                    surveyUrl && (
                      <Space direction='vertical'>
                        <Paragraph style={{ marginTop: 5, width: 200 }}>
                          <AntLink href={surveyUrl} copyable target='_blank' ellipsis>{surveyUrl}</AntLink>
                        </Paragraph>
                        <QRCode value={surveyUrl} size={200} />
                      </Space>
                    )
              }
            </Space>
          )}
        >
          <Button style={{ border: 'none', background: 'none', padding: '0', width: 'unset' }} icon={<QrcodeOutlined style={{ color: color.blue, fontSize: 16 }} />} onClick={() => {
            void generateSurveyToken({
              organizationId,
              studyId,
              checklistLogId,
              stepId,
            })
          }} />
        </Popover>
      </Tooltip>
    </Space>
  )
}

const ChecklistTable: React.FC<{ checklistLog: ChecklistLog }> = ({ checklistLog }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { Panel } = Collapse;
  const checklistId = checklistLog.checklist_id
  const { data: checklist } = useChecklist(organizationId, studyId, checklistId)
  const { message: messageApi } = App.useApp();

  const [updateChecklistLog] = useUpdateChecklistLog({
    onError: () => {
      void messageApi.error('Checklist log could not be updated')
    },
    onSuccess: () => {
      void messageApi.success('Checklist log updated');
    }
  })

  const [setChecklistLogMissed] = useSetChecklistLogMissed({
    onError: () => {
      void messageApi.error('Checklist log could not be updated')
    },
    onSuccess: () => {
      void messageApi.success('Checklist log updated');
    }
  })

  const updateLogValue = useCallback((checklistLogData: ChecklistLogValues[], checkListId: string) => {
    void updateChecklistLog({ organizationId, studyId, checklistLogId: checkListId, values: checklistLogData })
  }, [])

  const columns: ColumnsType<ChecklistLogData> = [
    {
      title: "Activity",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: function name(_, row) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='horizontal'>
              <Text>{row.display_name}</Text>
              {row.step_type === "survey" && (
                <ClinicSurveyToken checklistLogId={checklistLog.id} stepId={row.step_id} />
              )}
            </Space>
          </div>
        )
      }
    },
    {
      title: "Completed",
      dataIndex: "checked",
      key: "completed",
      width: "20%",
      align: 'center',
      render: function completed(_, row, index) {
        return (
          <Checkbox defaultChecked={row.checked} onChange={({ target }) => {
            if (row.checked !== target.checked) {
              const newValues = checklistLog.data.map(step => ({ step_id: step.step_id, checked: step.checked, notes: step.notes }))
              newValues[index].checked = target.checked
              updateLogValue(newValues, checklistLog.id)
            }
          }} />
        )
      }
    },
    {
      title: "Completed At",
      dataIndex: "completed_at",
      key: "completed_at",
      width: "20%",
      render: function name(_, row) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='vertical'>
              <Text>{row.completed_at ? moment.unix(row.completed_at).format('MMM DD LT') : ""}</Text>
              {row.sessions?.map(session => (
                <Text key={session.id}>
                  <Link to={generatePath('/organizations/:organizationId/studies/:studyId/datasets/participant/:virtualUserId/session/:sessionId', { organizationId, studyId, virtualUserId: session.user_id, sessionId: session.id })}>{session.id.substring(0, 8)}</Link>
                  {session.created_by_clinic && <Tooltip title="Clinician Initials">&nbsp;({session.created_by_clinic})</Tooltip>}
                </Text>
              ))}
            </Space>
          </div>
        )
      }
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: "20%",
      render: function notes(_, row, index) {
        return (
          <div>
            <TextArea defaultValue={row.notes} style={{ width: "190px", height: "100px" }} onBlur={({ target }) => {
              if (row.notes !== target.value) {
                const newValues = checklistLog.data.map(step => ({ step_id: step.step_id, checked: step.checked, notes: step.notes }))
                newValues[index].notes = target.value
                updateLogValue(newValues, checklistLog.id)
              }
            }} />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Collapse>
        <Panel showArrow={false} key={checklistLog.id} header={(
          <Space>
            {checklistLog.missed ? <IssuesCloseOutlined title="Missed" /> : checklistLog.is_complete ? <CheckCircleOutlined title='Complete' /> : <CloseCircleOutlined title='Not Complete' />}
            {checklistLog.checklist_name || checklist?.name}
          </Space>
        )}>
          <Space size="large" direction='vertical' style={{ width: '100%' }}>
            <Table
              columns={columns}
              dataSource={checklistLog.data}
              pagination={false}
              rowKey="step_id"
            />
            <Popconfirm title={`Are you sure you want to mark as ${checklistLog.missed ? "not" : ""} missed?`} onConfirm={() => void setChecklistLogMissed({ organizationId, studyId, checklistLogId: checklistLog.id, missed: !checklistLog.missed })}>
              <Button danger={!checklistLog.missed}>Mark {checklistLog.missed ? "Not" : ""} Missed</Button>
            </Popconfirm>
          </Space>
        </Panel>
      </Collapse>
    </>
  )
}

const Protocols: React.FC = () => {
  const { organizationId, studyId, virtualUserId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: checklistLogs, isLoading } = useChecklistLogs(organizationId, studyId, virtualUserId);
  const [protocolId, setProtocolId] = useState<string | undefined>(undefined)
  const { message: messageApi } = App.useApp();

  const [applyProtocol] = useApplyProtocol({
    onError: () => {
      void messageApi.error('Failed to apply protocol')
    },
    onSuccess: () => {
      void messageApi.success('Successfully applied protocol')
    }
  })

  if (isLoading) return <Loader />

  if (!checklistLogs?.protocol) {
    return (
      <Space direction='vertical' style={{ width: '100%' }} size="small">
        <Title level={3} style={{ marginTop: 0 }}>Protocols</Title>

        <Select
          style={{ width: 200 }}
          onSelect={setProtocolId}
        >
          {checklistLogs?.protocols?.map(protocol => (
            <Select.Option key={protocol.id} value={protocol.id}>{protocol.name}</Select.Option>
          ))}
        </Select>

        <Button
          type="primary"
          disabled={!protocolId}
          onClick={() => {
            void applyProtocol({ organizationId, studyId, virtualUserId, protocolId: protocolId as string })
          }}
        >Apply Protocol</Button>
      </Space>
    )
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }} size="small">
      <Title level={3} style={{ marginTop: 0 }}>{checklistLogs.protocol}</Title>
      <div>
        {
          (checklistLogs?.checklists ?? []).length > 0 && 
          <div style={{display: 'flex', justifyContent: 'space-between',padding: '16px'}}>
            <Text style={{fontWeight: 'bold', color: "#fff", flexBasis: '70%'}}>Checklists</Text>
            <Space className={styles.ChecklistDetails} style={{flexBasis: '30%', justifyContent: 'flex-end'}}>
              <Text style={{fontWeight: 'bold', color: "#fff"}}>Actions</Text>
            </Space>
          </div>
        }
        { 
          checklistLogs?.checklists?.map(checklistLog => (<ChecklistTable key={checklistLog.id} checklistLog={checklistLog} />))
        }
      </div>
    </Space>
  )
}

const Participant: React.FC = () => {
  const { organizationId, studyId, virtualUserId, activeTab } = useParams<{ organizationId: string, studyId: string, virtualUserId: string, activeTab: string }>();
  const { data: participant, error: participantError } = useParticipant(organizationId, studyId, virtualUserId)
  const { message: messageApi } = App.useApp();

  const { study } = useSelectedStudyContext();
  const canPay = study?._permissions?.can_pay;

  const [updateParticipant, {
    isLoading: updateParticipantIdLoading
  }] = useUpdateParticipant({
    onError: () => {
      void messageApi.error('Failed to set update participant ID')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated participant ID')
    }
  })

  const history = useHistory();
  const handleTabClick = (key: string) => {
    history.push(generatePath('/organizations/:organizationId/studies/:studyId/participants/:virtualUserId/:activeTab', { organizationId: organizationId, studyId: studyId, virtualUserId: virtualUserId, activeTab: key }))
  }
  const title = participant?.participant_id;

  const deactivated = participant && !participant?.active

  const canRenameParticipant = participant?._permissions.can_rename;

  return (
    <>
      <PageHeader
        title={
          <Space>
            {updateParticipantIdLoading ?
              <Loader /> :
              <Space direction="vertical" size="small">
                {deactivated && <Alert type="error" showIcon icon={<StopOutlined />} message="Participant Deactivated" />}
                {title ? (
                  <Title
                    style={{ marginTop: 0 }}
                    level={1}
                    {...canRenameParticipant && {
                      editable: {
                        icon: <EditOutlined style={{ fontSize: 24, color: color.blue }} />,
                        onChange: value => {
                          if (value && value !== participant?.participant_id) {
                            void updateParticipant({ participant_id: value, organizationId, studyId, virtualUserId })
                          }
                        }
                      }
                    }}
                  >
                    {title}
                  </Title>
                ) : <Loader />}
                {participantError && <span style={{ color: 'red' }}>{participantError?.message}</span>}
              </Space>
            }
          </Space>
        }
        extra={
          <Space size="large">
            {participant?._permissions.can_reinvite && <ReinviteParticipant />}
            <Tooltip title="Filter Datasets">
              <Link to={generatePath('/organizations/:organizationId/studies/:studyId/datasets/filter/participant/:virtualUserId', { organizationId, studyId, virtualUserId })}>
                <FilterOutlined style={{ color: color.blue }} />
              </Link>
            </Tooltip>
          </Space>
        }
        style={{ paddingTop: 0 }}
      >
        {participant && (
          <Tabs activeKey={activeTab} tabPosition="left" style={{ paddingTop: 10 }} size="large"
            onChange={(activeKey) => handleTabClick(activeKey)}
            items={[
              { key: "details", label: "Details", children: (
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                  <DataStreams />
                  {participant?._permissions.can_view_status && (
                    <>
                      <Divider />
                      <StatusHistory />
                    </>
                  )}
                </Space>
              ) },
              ...(study?._permissions.can_manage_checklist ? [{ key: "protocols", label: "Protocols", children: <Protocols /> }] : []),
              ...(!participant.pending && participant?._permissions.can_manage_documents ? [{ key: "documents", label: "Documents", children: (
                <Space direction="vertical" size="large">
                  <Documents document="ICF" />
                  <Documents document="W9" />
                  <Documents document="NDA" />
                </Space>
              ) }] : []),
              ...(study?._permissions.can_message ? [{ key: "messages", label: "Messages", children: <Messages activeTab={activeTab} canEmail={!!participant?._permissions.can_email} canSms={!!participant?._permissions.can_sms} canChat={participant && !participant.pending} /> }] : []),
              { key: "notes", label: "Notes", children: <Notes type="participant" args={{ organizationId, studyId, virtualUserId }} /> },
              ...(participant?.enrollment_survey_answers ? [{ key: "enrollmentSurvey", label: "Enrollment Survey", children: <EnrollmentSurveyAnswers answers={participant.enrollment_survey_answers} /> }] : []),
              ...(!participant.pending && study?._permissions.can_rate ? [{ key: "ratings", label: "Ratings", children: <Ratings /> }] : []),
              ...(!participant.pending && canPay ? [{ key: "payments", label: "Payments", children: <Payments /> }] : []),
              ...(!participant.pending && study?._permissions.can_view_study_reports ? [{ key: "reports", label: "Deliver Reports", children: <StudyParticipantReports /> }] : []),
            ]}  
          />
        )}
      </PageHeader>
    </>
  )
}

export default Participant
