import React, { useEffect, useState } from 'react';
import { LeftOutlined, MailFilled, QrcodeOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { QrCodeDisplay, QrCodeProps } from './QrCodes';
import { Button, Input, Space } from 'antd';

export const Mantra: React.FC = () => {
    return (
      <div className={styles.mantra}>
        Better data.<br />Better brains.
      </div>
    );
}

type CarouselFormProps = {
    inputValue: string;
    checkboxValue?: boolean;
    inputLabel: string;
    inputValidator?: (value: string) => boolean;
    onInputChange?: (value: string) => void;
    subtext: string;
    CheckboxContent?: React.FC;
    onCheckboxChange?: (value: boolean) => void;
    primaryButtonText: string;
    onPrimaryButtonClick: () => void;
    secondaryButtonText?: string;
    onSecondaryButtonClick?: () => void;
    qrCodes?: QrCodeProps;
    extra?: React.ReactNode
} & CarouselHeaderProps & Partial<LoadingErrorProps>;

type LoadingErrorProps = {
    isPrimaryLoading?: boolean;
    isSecondaryLoading?: boolean;
    isError?: boolean;
    error?: string;
}

export const CarouselForm: React.FC<CarouselFormProps> = ({ 
    goBack, 
    inputValue,
    checkboxValue,
    headerLabel, 
    inputLabel, 
    inputValidator,
    onInputChange,
    subtext,
    CheckboxContent,
    onCheckboxChange,
    primaryButtonText,
    onPrimaryButtonClick,
    secondaryButtonText,
    onSecondaryButtonClick,
    isPrimaryLoading,
    isSecondaryLoading,
    isError,
    error,
    qrCodes,
    extra,
}) => {

    const [disabled, setDisabled] = useState(true);
    useEffect(() => { 
      setDisabled((inputValidator && !inputValidator(inputValue)) || checkboxValue === false);
    }, [inputValue, checkboxValue]);

    const [activeTab, setActiveTab] = useState<ConfirmTab>(ConfirmTab.EmailCode);

    return (
      <div className={styles['carousel-form']}>
        <CarouselHeader goBack={goBack} headerLabel={headerLabel} />
        { qrCodes && <CarouselTabs activeTab={activeTab} setActiveTab={setActiveTab} /> }
        {
          (!qrCodes || activeTab === ConfirmTab.EmailCode) &&
          <>        
            {/* Input area */}
            <CarouselLabel>{inputLabel}</CarouselLabel>
            <Input 
              size="large"
              className={isError ? styles['error'] : ''}
              onChange={(e) => { return onInputChange && onInputChange(e.target.value) }} 
              onPressEnter={onPrimaryButtonClick}
              placeholder={inputLabel ? inputLabel : 'Enter...'}
              value={inputValue}
            />
            <p className={styles['subtext']}>{subtext}</p>

            {(CheckboxContent && onCheckboxChange) &&
              <CarouselCheckbox
                onCheckboxChange={onCheckboxChange}
                CheckboxContent={CheckboxContent}
                checked={checkboxValue}
              />
            }

            {/* Button Panel */}
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button type="primary" size="large" block disabled={disabled} onClick={onPrimaryButtonClick} loading={isPrimaryLoading}>{primaryButtonText}</Button>
              {(secondaryButtonText && onSecondaryButtonClick) &&
                <Button size="large" block onClick={onSecondaryButtonClick} loading={isSecondaryLoading}>{secondaryButtonText}</Button> }
            </Space>
          </>
        }
        {
          (qrCodes && activeTab === ConfirmTab.QrCode) &&
            <QrCodeDisplay {...qrCodes} />
        }
        { isError && <Error>{error}</Error> }
        {extra}
      </div>
    )
}

enum ConfirmTab {
  EmailCode,
  QrCode
}
type CarouselTabProps = {
  activeTab: ConfirmTab;
  setActiveTab: (tab: ConfirmTab) => void;
}
const CarouselTabs: React.FC<CarouselTabProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div>
      <button 
        onClick={() => setActiveTab(ConfirmTab.EmailCode)}
        className={`${styles['tab']} ${activeTab === ConfirmTab.EmailCode ? styles['active'] : styles['inactive']}`}
      >
        <MailFilled />
        <div className={`${styles['tab-text']}`}>Email Code</div>
      </button>
      <button 
        onClick={() => setActiveTab(ConfirmTab.QrCode)}
        className={`${styles['tab']} ${activeTab === ConfirmTab.QrCode ? styles['active'] : styles['inactive']}`}
      >
        <QrcodeOutlined />
        <div className={`${styles['tab-text']}`}>QR Code</div>
      </button>
    </div>
  )
}
  
export const Error: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={styles['error-message']}>{children}</div>
  )
}

type CarouselHeaderProps = {
    goBack: () => void;
    headerLabel: string;
}
export const CarouselHeader: React.FC<CarouselHeaderProps> = ({ goBack, headerLabel }) => {
    return (
        <div className={styles['carousel-header']}>
            <LeftOutlined onClick={goBack} />
            <CarouselLabel>{headerLabel}</CarouselLabel>
        </div>
    )
}
  
const CarouselLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className={styles['carousel-label']}>{children}</div>
    )
}
  
type CarouselCheckboxProps = {
    onCheckboxChange: (value: boolean) => void;
    CheckboxContent: React.FC;
    checked?: boolean;
}
const CarouselCheckbox: React.FC<CarouselCheckboxProps> = ({ onCheckboxChange, CheckboxContent, checked }) => {
    return (
        <div className={styles['carousel-checkbox-container']} onClick={() => onCheckboxChange(!checked)}>
            <input type='checkbox' onChange={(e) => onCheckboxChange(e.target.checked)} checked={checked} />
            <label>
              <CheckboxContent />
            </label>
        </div>
    )
}

export const Policies: React.FC = () => {
  return (
    <>
      I agree to the Kernel {' '}
      <a className='strong' target='_blank' rel='noreferrer' href='https://docs.kernel.com/docs/researcher-terms-of-service'>Terms of Service</a>
      {' & '}
      <a className='strong' target='_blank' rel='noreferrer' href='https://docs.kernel.com/docs/services-privacy-policy'>Privacy Policy</a>
    </>
  )
}
