import { PageHeader } from "@ant-design/pro-components";
import { Alert, App, Button, Card, Space, Spin, Tabs, Tag, Typography } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Organization, useCreateOrganizationExternalApiKey } from "../../api";
import { DataStreams as OrganizationDataStreams } from '../../components/OrganizationRegisteredDevices';
import { Members } from '../../components/OrganizationResearchers';
import { useSelectedOrganizationContext } from "../../contexts/SelectedOrganization";

const { Link, Text, Title, Paragraph } = Typography

const AcquisitionDriver: React.FC<{ organization: Organization }> = ({ organization }) => (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%', padding: '12px 0' }}>
        <Space direction="vertical">
          <Title level={5} style={{ margin: 0 }}>Acquisition Driver</Title>
        </Space>
      </Space>
      <Card>
        <Tabs items={[
                {
                    key: 'installation',
                    label: 'Installation',
                    children: (
                        <Space direction="vertical" size="large">
                            <Alert type="info" showIcon message="Supported Operating Systems" description={(
                                <>
                                    <ul>
                                        <li>Linux Ubuntu 20.04 (x86_64)</li>
                                        <li>Linux Ubuntu 22.04 (x86_64)</li>
                                        <li>macOS (amd64)</li>
                                        <li>macOS (arm64)</li>
                                        <li>Windows (x86_64) <Tag color="warning">beta</Tag></li>
                                    </ul>
                                </>
                            )} />
                            <Alert type="warning" showIcon message="Do not share the code below with people outside your organization" />
                            <Paragraph>
                                To install the acquisition driver on <Text strong>Linux</Text> or <Text strong>macOS</Text>:
                                <ul>
                                    <li>Open a terminal</li>
                                    <li>Enter root via <Text code copyable>sudo su -</Text></li>
                                    <li>Run <Text code copyable>{`curl --proto '=https' --tlsv1.2 -sSf https://${window.location.hostname.replace("portal.", "software.")}/install/kortex/${organization.kortex_jwt_secret} | /bin/bash`}</Text></li>
                                </ul>
                            </Paragraph>
                            <Paragraph>
                                <Alert type="warning" showIcon message="Note that this feature is in beta and please contact support should you encounter any issues" />
                                To install the acquisition driver on <Text strong>Windows</Text>:
                                <ul>
                                    <li>
                                        Download and install <Link href="https://zadig.akeo.ie/" target="_blank">Zadig</Link>
                                        <ul>
                                            <li>Plug the Flow headset in and power it on</li>
                                            <li>Wait until it is recognized by <Text code>Zadig</Text> and select it in the dropdown of devices</li>
                                            <li>Make sure <Text code>WinUSB</Text> is selected in the driver dropdown</li>
                                            <li>Click <Text code>Install Driver</Text> and follow instructions</li>
                                            <li>Close <Text code>Zadig</Text> after it installs successfully</li>
                                        </ul>
                                    </li>
                                    <li>Open <Text code>Powershell</Text> as an Administrator</li>
                                    <li>Run <Text code copyable>{`iwr https://${window.location.hostname.replace("portal.", "software.")}/install/kortex/windows/${organization.kortex_jwt_secret} -UseBasicParsing | iex`}</Text></li>
                                </ul>
                            </Paragraph>
                        </Space>
                    )
                },
                {
                    key: 'restarting',
                    label: 'Restarting',
                    children: (
                        <Space direction="vertical" size="large">
                            <Alert type="warning" showIcon message="Only to be used in the case of issues; please contact support first." />
                            <Paragraph>
                                To restart the acquisition driver:
                                <ul>
                                    <li>On <Text strong>Linux</Text>, run <Text code copyable>sudo systemctl restart kernel_kortex</Text></li>
                                    <li>On <Text strong>macOS</Text>, run <Text code copyable>sudo launchctl kickstart -k system/com.kernel.kortex</Text></li>
                                    <li>On <Text strong>Windows</Text>, launch the <Text code>Services App</Text>, select the <Text code>Kortex</Text> service and click <Text code>Restart</Text></li>
                                </ul>
                            </Paragraph>
                        </Space>
                    )
                }
            ]} />
        </Card>
    </Space>
)

const OfflineRecording: React.FC = () => (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={5} style={{ marginTop: 10 }}>Offline Recording <Tag color="warning">beta</Tag></Title>
      <Alert type="warning" showIcon message="Note that this feature is in beta and please contact support should you encounter any issues" />
      <Paragraph>
        You can navigate to <Link href="http://127.0.0.1:13254/offline" target="_blank" copyable>http://127.0.0.1:13254/offline</Link> to record offline.
        <ul>
            <li>Please bookmark the link so that it is available for you Offline</li>
            <li>You should not have the online Flow UI and offline Flow UI open at the same time</li>
            <li>When switching between online and offline, please power cycle the Flow headset and re-initialize</li>
        </ul>
      </Paragraph>
    </Space>
)

const ApiKey: React.FC<{ organizationId: string }> = ({ organizationId }) => {
    const [apiKey, setApiKey] = useState<string>("");
    const { message: messageApi } = App.useApp();

    const [createApiKey, { isLoading: createApiKeyLoading } ] = useCreateOrganizationExternalApiKey({
        onSuccess: () => {
            void messageApi.success("API Key created successfully!");
        },
        onError: () => {
            void messageApi.error("Failed to create API Key");
        }
    });

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Title level={5} style={{ marginTop: 10 }}>API Key</Title>
            <Paragraph>
                <Button type="primary" onClick={async () => {
                    const data = await createApiKey({ organizationId });
                    if (data) {
                        setApiKey(data.api_key);
                    }
                } } loading={createApiKeyLoading}>
                    Generate API Key
                </Button>
            </Paragraph>
            {apiKey && (
                <Space direction="vertical" style={{ marginTop: 10 }}>
                    <Alert type="warning" showIcon description={
                        <ul>
                            <li>The API Key will no longer be visible once you leave this page</li>
                            <li>It is a secret and should be kept private</li>
                            <li>Do not share it with anyone outside your organization</li>
                            <li>Do not store it in a public repository</li>
                        </ul>

                    } />
                    <Text copyable code style={{ marginLeft: 10 }}>api_key_{process.env.REACT_APP_NEUROME_ENV}.{apiKey}</Text>
                </Space>
            )}
        </Space>
    )
}

const OrganizationSettings: React.FC = () => {
    const { organization } = useSelectedOrganizationContext()
    const { hash } = useLocation();

    if (!organization) {
        return <Spin />
    }

    return (
        <PageHeader
            style={{ paddingTop: 0 }}
            title={<Title level={3} style={{ marginTop: 24 }}>{organization.name}: Settings</Title>}
        >
            <Tabs tabPosition="left" size="large" defaultActiveKey={hash === "#acquisition_driver" ? "AcquisitionDriver" : undefined} items={[
                { key: "Researchers", label: "Researchers", children: <Members /> },
                { key: "OrganizationDataStreams", label: "Devices", children: <OrganizationDataStreams /> },
                { key: "AcquisitionDriver", label: "Acquisition Driver", children: <AcquisitionDriver organization={organization} /> },
                { key: "Offline", label: <>Offline Recording <Tag color="warning">beta</Tag></>, children: <OfflineRecording /> },
                ...(
                    organization._permissions.can_generate_api_key ?
                    [{ key: "ApiKey", label: "API Key", children: <ApiKey organizationId={organization.id} /> }] :
                    []
                ),
            ]} />
        </PageHeader>
    )
}

export default OrganizationSettings
