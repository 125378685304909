import { Button, Modal, Typography, Space, Tabs, Tag, Alert } from "antd";
import React from "react";
import createPersistedState from 'use-persisted-state'

const { Title, Paragraph } = Typography;

const useReleaseNoteState = createPersistedState('release-notes-2025-02-06');

/*
const QC: React.FC = () => (
  <Space direction="vertical" size="small">
    <Title level={5} style={{ marginTop: 10 }}>Flow UI: Real-time Data Quality Checks</Title>
    <Paragraph>
      <Text>You may see warnings related to Bad Data Quality.</Text>
      <ul>
        <li><Text strong>After Tuning</Text>: If the headset is not well positioned.</li>
        <li><Text strong>During Recording</Text>: If there is too much motion from the participant.</li>
        <li><Text strong>After Recording</Text>: If there are missing task events.</li>
      </ul>
    </Paragraph>
  </Space>
)
*/

const Offline: React.FC = () => (
  <Space direction="vertical" size="small">
    <Title level={5} style={{ marginTop: 10 }}>Offline Recording <Tag color="warning">beta</Tag></Title>
    <Alert type="warning" showIcon message="Note that this feature is in beta and please contact support should you encounter any issues" />
    <Paragraph>You can now record Flow without an internet connection. This will generate a local SNIRF file on your computer that you can then analyze. To record offline, see Organization Settings &gt; Offline Recording</Paragraph>
  </Space>
)

const Windows: React.FC = () => (
  <Space direction="vertical" size="small">
    <Title level={5} style={{ marginTop: 10 }}>Windows Support <Tag color="warning">beta</Tag></Title>
    <Alert type="warning" showIcon message="Note that this feature is in beta and please contact support should you encounter any issues" />
    <Paragraph>You can now run Flow on a Windows machine. If you want to install the Acquisition Driver on Windows, see the installation instructions under Organization Settings &gt; Acquisition Driver.</Paragraph>
  </Space>
)

const ReleaseNotes : React.FC = () => {
  const [seen, setSeen] = useReleaseNoteState(false)

  return (
    <Modal open={!seen} title={<Title level={3} style={{ marginBottom: 20 }}>What&apos;s New: February 6, 2025</Title>} width={800} closable={false} footer={[
      <Button key="submit" type="primary" onClick={() => setSeen(true)}>
        Ok
      </Button>,
    ]}>
      <Tabs tabPosition="left" size="large" items={[
        // { key: "qc", label: "Flow UI: Real-time Data Quality Checks", children: <QC /> },
        { key: "offline", label: <>Offline Recording <Tag color="warning">beta</Tag></>, children: <Offline /> },
        { key: "windows", label: <>Windows Support <Tag color="warning">beta</Tag></>, children: <Windows /> },
      ]} />
    </Modal>
  )
}

export default ReleaseNotes