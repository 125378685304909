import { App, Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select, Space, Tag, Tooltip, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-components"
import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { Checklist, ChecklistStepType, Step, useAddChecklist, useAllDataStreams, useExperiments, useUpdateChecklist } from "../../api"
import SortableTable, { DragHandle } from "../DragDropTable";
import styles from './styles.module.css'
import { MinusCircleOutlined, EditOutlined, UserOutlined, MedicineBoxOutlined, AimOutlined, InfoCircleOutlined, PlayCircleOutlined, FormOutlined } from '@ant-design/icons'
import { ColumnsType } from "antd/lib/table";
import { color } from "../../theme";

const { Text } = Typography

export type ChecklistBuilderActions = 'create' | 'clone' | 'edit'

type ChecklistBuilderProps = {
  checklist?: Checklist
  action: ChecklistBuilderActions
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

export const ChecklistBuilder: React.FC<ChecklistBuilderProps> = ({ checklist, action, setIsVisible }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [checklistForm] = Form.useForm()
  const [checklistName, setChecklistName] = useState<string>((checklist?.name ?? "New Checklist"));
  const [editStepId, setEditStepId] = useState<string>();
  const [editStepType, setEditStepType] = useState<ChecklistStepType>()
  const { data: dataStreams } = useAllDataStreams(organizationId, studyId)
  const { data: experiments } = useExperiments(organizationId, studyId)
  const { message: messageApi } = App.useApp();
  const [optional, setOptional] = useState<boolean>(false)
  const [clinicianAdministered, setClinicianAdministered] = useState<boolean>()

  const [saveChecklist] = useAddChecklist({
    onError: () => {
      void messageApi.error('Failed to create checklist')
    },
    onSuccess: () => {
      void messageApi.success('Successfully created checklist')
      setIsVisible(false)
    }
  })

  const [updateChecklists] = useUpdateChecklist({
    onError: () => {
      void messageApi.error('Failed to update checklist')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated checklist')
      setIsVisible(false)
    }
  })

  const [steps, setSteps] = useState<Step[]>((checklist?.steps ?? []))

  const removeStep = (value: Step) => {
    setSteps(steps.filter(step => step.id !== value.id))
  }

  const addStep = (step: Step) => {
    if (editStepType === "session") {
      step["optional"] = optional ? "true" : "false"
    }
    if (editStepType === "survey") {
      step["clinician_administered"] = clinicianAdministered ? "true" : "false"
    }
    if (!editStepId) {
      setSteps(steps => ([...steps, step]))
    } else {
      const editedSteps = [...steps]
      const editIndex = editedSteps.findIndex(step => step.id === editStepId)
      editedSteps[editIndex] = step
      setSteps(editedSteps)
    }

    checklistForm.resetFields()
    setOptional(false)
    setClinicianAdministered(false)
    setEditStepId(undefined)
    setEditStepType(undefined)
  }

  const validateMessages = {
    required: "'${label}' is required"
  }

  const columns: ColumnsType<Step> = [
    {
      title: "Name",
      dataIndex: "display_name",
      key: "name",
      width: "60%",
      render: function name(display_name, record) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='horizontal'>
              <DragHandle />
              <Text>{display_name}</Text>
              {record.optional === "true" && <Tag color="warning">Optional</Tag>}
            </Space>
          </div>
        )
      }
    },
    {
      title: "Type",
      dataIndex: "step_type",
      key: "step_type",
      width: "30%",
      render: function stepType(stepType: ChecklistStepType, row) {
        if (stepType === "session") {
          const experiment = experiments?.find(experiment => experiment.name === row["experiment_name"])
          return (
            <Space size="small">
              {
                row["session_mode"] === "instructions" ? <Tooltip title="Show Task Instructions"><InfoCircleOutlined /></Tooltip> :
                row["session_mode"] === "practice" ? <Tooltip title="Practice Task"><AimOutlined /></Tooltip> :
                <Tooltip title="Record Task"><PlayCircleOutlined /></Tooltip>
              }
              {row["session_number"] && <Tooltip title="Session Number"><Text code>{row["session_number"]}</Text></Tooltip>}
              <Text>{experiment ? (experiment.display_name || experiment.name) : row["experiment_name"]}</Text>
            </Space>
          )
        } else if (stepType === "survey") {
          const survey = dataStreams?.find(dataStream => dataStream.id === row["data_stream_id"])
          return (
            <Space size="small" direction="vertical">
              {row["clinician_administered"] === "true" ? (
                <Tag color="error" icon={<MedicineBoxOutlined />}>Clinician Administered</Tag>
              ) : (
                <Tag color="warning" icon={<UserOutlined />}>Self Administered</Tag>
              )}
              <Space size="small">
                <Tooltip title="Survey"><FormOutlined /></Tooltip>
                <Text>{survey?.display_name || survey?.name}</Text>
              </Space>
            </Space>
          )
        }
      }
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "right",
      render: function actions(_, row) {
        const captureRow = row;
        return (
          <div style={{ width: "100%" }}>
            <Space style={{ gap: "20px" }}>
              <Popconfirm arrow={{ pointAtCenter: true }} title={() => <Text>Are you sure you want to edit this step? You will lose any unsubmitted data in the Add Step Form.</Text>}
                onConfirm={() => {
                  setEditStepId(captureRow.id)
                  setEditStepType(captureRow.step_type)
                  checklistForm.setFieldsValue(captureRow)
                  setOptional(captureRow.optional === "true")
                  setClinicianAdministered(captureRow["clinician_administered"] === "true")
                }}>
                <EditOutlined style={{ color: color.blue }} />
              </Popconfirm>
              <Popconfirm arrow={{ pointAtCenter: true }} title={() => <Text>Are you sure you want to remove this step?</Text>}
                onConfirm={() => removeStep(captureRow)}>
                <Tooltip title='Remove Step' placement="top">
                  <MinusCircleOutlined style={{ color: color.red }} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>
        )
      }
    }
  ]
  return (
    <PageHeader>
      <Space direction="horizontal" align='start' style={{ width: "100%", justifyContent: "space-between", marginBottom: "15px" }}>
        <Space direction='vertical'>
          <Text editable={{ onChange: setChecklistName, icon: <EditOutlined style={{ fontSize: "24px", color: color.blue }} /> }} style={{ fontWeight: 600, fontSize: "24px" }}>
            {checklistName}
          </Text>
          {
            (action === 'clone' && checklistName === checklist?.name) && <Text>Please rename checklist before saving.</Text>
          }
        </Space>
        <Space size={16}>
        <Button onClick={() => setIsVisible(false)} style={{ width: "111px" }}>Cancel</Button>
          <Button type='primary' style={{ width: "111px" }}
            onClick={() => {
              if (action === 'edit' && checklist) void updateChecklists({ studyId, organizationId, checklistId: checklist.id, name: checklistName, steps: steps })
              else void saveChecklist({ studyId, organizationId, name: checklistName, steps: steps })
            }}>
            {action === 'edit' ? 'Update' : 'Submit'}
          </Button>
        </Space>
      </Space>
      <Space direction="horizontal" size="large" align="start" className={styles.ChecklistBuilderContent}>
        <Form
          className={styles.ChecklistBuilder}
          name="add-question"
          form={checklistForm}
          initialValues={{ step_type: "" }}
          validateMessages={validateMessages}
          onValuesChange={(_, newStep) => setEditStepType(newStep.step_type)}
          onFinish={addStep}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item label="Type" colon={false} name="step_type" className={`${styles.FormItem} w-full`}>
            <Select>
              <Select.Option value="">Activity</Select.Option>
              <Select.Option value="survey">Survey</Select.Option>
              <Select.Option value="session">Session</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="ID" colon={false} name="id" className={`${styles.FormItem} w-full`}
            rules={[
              { required: true },
              {
                message: "Question ID must be unique.",
                validator: (_, value) => {
                  if (!editStepId && steps.filter(step => step.id === value).length === 0) return Promise.resolve()
                  else if (editStepId && steps.filter(step => step.id === value && step.id !== editStepId).length === 0) return Promise.resolve()
                  return Promise.reject()
                }
              }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Name" colon={false} name="display_name" className={`${styles.FormItem} w-full`} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {editStepType == "session" ? (
            <>
              <Form.Item rules={[{ required: true }]} label="Task" colon={false} name="experiment_name" className={`${styles.FormItem} w-full`}>
                {experiments && (
                  <Select>
                    <Select.Option>None</Select.Option>
                    {experiments.map(experiment => <Select.Option key={experiment.id} value={experiment.name}>{experiment.display_name || experiment.name}</Select.Option>)}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={<>Mode</>} colon={false} name="session_mode" className={`${styles.FormItem} w-full`}>
                <Select>
                  <Select.Option>Run Session</Select.Option>
                  <Select.Option value="instructions">Show Instructions</Select.Option>
                  <Select.Option value="practice">Practice</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={<>Session<br />Number</>} colon={false} name="session_number" className={`${styles.FormItem} w-full`}>
                <InputNumber />
              </Form.Item>
              <Form.Item label={<>Session<br />Name</>} colon={false} name="session_name" className={`${styles.FormItem} w-full`}>
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 18 }} colon={false} name="optional" className={`${styles.FormItem} w-full`}>
                <Checkbox checked={optional} onChange={({target}) => setOptional(target.checked) }>Optional</Checkbox>
              </Form.Item>
            </>
          ) : editStepType == "survey" ? (
            <>
              <Form.Item rules={[{ required: true }]} label="Survey" colon={false} name="data_stream_id" className={`${styles.FormItem} w-full`}>
                {dataStreams && (
                  <Select>
                    <Select.Option>None</Select.Option>
                    {dataStreams.filter(dataStream => dataStream.data_transform.integration === "survey").map(dataStream => <Select.Option key={dataStream.id} value={dataStream.id}>{dataStream.name}</Select.Option>)}
                  </Select>
                )}
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 18 }} colon={false} name="clinician_administered" className={`${styles.FormItem} w-full`}>
                <Checkbox checked={clinicianAdministered} onChange={({target}) => setClinicianAdministered(target.checked) }>Clinician Administered</Checkbox>
              </Form.Item>
            </>
          ) : <></>
          }
          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Button htmlType='submit' type="primary" block>
              {editStepId ? "Update Step" : "Add Step"}
            </Button>
          </Form.Item>
        </Form>
        <SortableTable data={steps} setData={setSteps} columns={columns} getSortKey={i => i.id} rowKey="id" />
      </Space>
    </PageHeader>
  )
}