import { Alert, App, Button, Image } from "antd";
import { default as React, useEffect, useRef, useState } from "react";
import { CameraOutlined, RollbackOutlined } from "@ant-design/icons";
import { FaceLandmarker, FilesetResolver, DrawingUtils, NormalizedLandmark } from "@mediapipe/tasks-vision";

const WIDTH = 800

// this skips the first and last N points of the face oval to avoid covering the forehead
const SKIP_FACE_INDICES = 5

const drawFace = (ctx: CanvasRenderingContext2D, landmarks: NormalizedLandmark[]) => {
  const drawingUtils = new DrawingUtils(ctx);
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_TESSELATION,
    { color: "#C0C0C070", lineWidth: 1 }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
    { color: "#FFF" }
  );
  drawingUtils.drawConnectors(
    landmarks,
    FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
    { color: "#FFF" }
  );
}

export const DrawPhoto: React.FC<{ photo: string, landmarks: NormalizedLandmark[] }> = ({ photo, landmarks }) => {
  const canvas = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!canvas.current || !landmarks) return;

    const ctx = canvas.current.getContext('2d');
    if (!ctx) return;

    const image = document.createElement('img');
    image.src = photo;
    image.onload = () => {
      if (!canvas.current) return;
      canvas.current.width = image.width;
      canvas.current.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);

      drawFace(ctx, landmarks)
    }
  }, [photo, landmarks])

  return <canvas ref={canvas}></canvas>
}

const CapturePhoto: React.FC<{ savePhoto: (photo?: string, landmarks?: NormalizedLandmark[]) => void }> = ({ savePhoto }) => {
  const [capturedPhoto, setCapturedPhoto] = useState<string>()
  const video = useRef<HTMLVideoElement>(null)
  const stream = useRef<MediaStream>()
  const { message: messageApi } = App.useApp()
  const faceLandmarker = useRef<FaceLandmarker>()
  const lastVideoTime = useRef<number>(-1)
  const lastFaceLandmarks = useRef<NormalizedLandmark[]>()
  const [visionTaskReady, setVisionTaskReady] = useState<boolean>(false)

  useEffect(() => {
    savePhoto(undefined, [])
  }, [])

  useEffect(() => {
    let unmounted = false;

    void (async () => {
      if (!video.current) return;

      try {
        stream.current = await navigator.mediaDevices.getUserMedia({ video: true });
        video.current.srcObject = stream.current;
        await video.current.play();

        if (unmounted) {
          if (stream.current) stream.current.getTracks().forEach(track => track.stop())
        }
      } catch (error) {
        if (!(error as DOMException).message?.includes("The play() request was interrupted by a new load request")) {
          void messageApi.error("Error accessing camera")
        }
      }
    })()

    return () => {
      unmounted = true;
      if (stream.current) stream.current.getTracks().forEach(track => track.stop())
    }
  }, [video.current]);

  useEffect(() => {
    let predictVideoCancel: number;
    let unmounted = false;

    void (async () => {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        `${process.env.PUBLIC_URL}/mediapipe/tasks-vision/wasm`
      );
      faceLandmarker.current = await FaceLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {
          modelAssetPath: `${process.env.PUBLIC_URL}/mediapipe/tasks-vision/face_landmarker.task`,
          delegate: "GPU"
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1
      });

      const predictVideo = () => {
        if (!faceLandmarker.current) {
          if (!unmounted) predictVideoCancel = window.requestAnimationFrame(predictVideo);
          return;
        }

        if (!video.current || video.current.readyState < 2) { // < HAVE_CURRENT_DATA 
          if (!unmounted) predictVideoCancel = window.requestAnimationFrame(predictVideo);
          return;
        }

        if (lastVideoTime.current !== video.current.currentTime) {
          lastVideoTime.current = video.current.currentTime;
          const results = faceLandmarker.current.detectForVideo(video.current, performance.now());
          setVisionTaskReady(true)
          lastFaceLandmarks.current = []
          if (results.faceLandmarks.length === 1) {
            lastFaceLandmarks.current = results.faceLandmarks[0]
          }
        }

        if (!unmounted) predictVideoCancel = window.requestAnimationFrame(predictVideo);
      };

      if (!unmounted) predictVideoCancel = window.requestAnimationFrame(predictVideo);
    })();

    return () => {
      unmounted = true;
      if (predictVideoCancel) window.cancelAnimationFrame(predictVideoCancel);
      if (faceLandmarker.current) faceLandmarker.current.close();
    }
  }, [])

  return (
    <>
      <div style={{ display: capturedPhoto ? 'flex' : 'none', flexDirection: 'column', gap: '5px' }}>
        <Button size="large" style={{ width: WIDTH }} onClick={() => { setCapturedPhoto(undefined); savePhoto(undefined, []) }} type="primary" icon={<RollbackOutlined />}>Retake Photo</Button>
        <Image width={WIDTH} src={capturedPhoto} style={{ transform: "rotateY(180deg)" }} />
      </div>
      <div style={{ display: !capturedPhoto ? 'flex' : 'none', flexDirection: 'column', gap: '5px', position: "relative" }}>
        <Button disabled={!visionTaskReady} size="large" style={{ width: WIDTH }} icon={<CameraOutlined />} onClick={() => {
          if (!video.current) {
            void messageApi.error("Error capturing photo")
            return
          }

          const faceLandmarks = lastFaceLandmarks.current
          if (!faceLandmarks || faceLandmarks.length === 0) {
            void messageApi.error("No face detected")
            return
          }

          const canvas = document.createElement('canvas');
          canvas.width = video.current.videoWidth;
          canvas.height = video.current.videoHeight;
          const ctx = canvas.getContext('2d');
          if (!ctx) {
            void messageApi.error("Error capturing photo")
            return
          }

          // check if face is inside the circle defined by the div w/ borderRadius below
          const circleCenter = { x: canvas.width / 2, y: canvas.height / 2 }
          const circleRadius = WIDTH / 4
          const faceInCircle = FaceLandmarker.FACE_LANDMARKS_FACE_OVAL
            .filter((_, idx) => idx > SKIP_FACE_INDICES && idx < FaceLandmarker.FACE_LANDMARKS_FACE_OVAL.length - (SKIP_FACE_INDICES - 1))
            .map(connection => faceLandmarks[connection.start])
            .map(({ x, y }) => ({ x: x * canvas.width, y: y * canvas.height }))
            .every(({ x, y }) => (x - circleCenter.x) ** 2 + (y - circleCenter.y) ** 2 < circleRadius ** 2);
          if (!faceInCircle) {
            void messageApi.error("Face is not completely inside the circle")
            return
          }

          ctx.drawImage(video.current, 0, 0, canvas.width, canvas.height);

          ctx.beginPath();
          const start = faceLandmarks[FaceLandmarker.FACE_LANDMARKS_FACE_OVAL[SKIP_FACE_INDICES].start];
          ctx.moveTo(start.x * canvas.width, start.y * canvas.height);
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL.filter((_, idx) => idx > SKIP_FACE_INDICES && idx < FaceLandmarker.FACE_LANDMARKS_FACE_OVAL.length - (SKIP_FACE_INDICES - 1)).forEach(connection => {
            const start = faceLandmarks[connection.start];
            ctx.lineTo(start.x * canvas.width, start.y * canvas.height);
          });
          ctx.closePath();
          ctx.fillStyle = 'black';
          ctx.fill();

          const dataUrl = canvas.toDataURL('image/png');
          setCapturedPhoto(dataUrl)
          
          const base64Photo = dataUrl.split(',')[1]
          savePhoto(base64Photo, faceLandmarks)
        }} type="primary">{ visionTaskReady ? "Capture Photo" : "Loading ..." }</Button>
        <Alert message="Please make sure your face is inside the circle" type="info" showIcon style={{ width: WIDTH }} />
        <video ref={video} width={WIDTH} style={{ transform: "rotateY(180deg)" }}></video>
        {visionTaskReady && (
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: WIDTH,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
          }}>
            <div style={{
              width: WIDTH / 2,
              height: WIDTH / 2,
              borderRadius: "50%",
              border: "5px solid green",
            }} />
          </div>
        )}
      </div>
    </>
  )
}

export default CapturePhoto;