import React, { useState, useCallback, FC } from 'react'
import { studies as getStudies, useCreateStudy, Study } from '../../api'
import { useParams, useHistory } from "react-router-dom";
import { Space, Typography, Form, Input, Empty, Row, Col, SpaceProps, Tooltip, Alert, App } from 'antd'
import { PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import TooltipButton from '../TooltipButton'
import styles from './styles.module.css'
import { ClickableCard } from '../ClickableCard'
import { Loader } from '../Loader'
import { color } from '../../theme';
import { useSelectedOrganizationContext } from '../../contexts/SelectedOrganization';

const { Text, Title, Paragraph } = Typography;

const CreateStudy: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [form] = Form.useForm()
  const { message: messageApi } = App.useApp();
  const [createStudy, { isLoading: createStudyLoading }] = useCreateStudy({
    onError: () => {
      void messageApi.error('Failed to create study')
    },
    onSuccess: () => {
      void messageApi.success('Study created')
      form.resetFields()
    }
  })

  const createStudyFormFinishHandler = useCallback(() => {
    void createStudy({ name: form.getFieldValue('name') as string, organizationId })
  }, [createStudy, form, organizationId])

  const [value, setValue] = useState<string>()

  const { organization } = useSelectedOrganizationContext()

  if (!organization?._permissions.can_create_study) {
    return <></>
  }

  return (
    <Form form={form} onFinish={createStudyFormFinishHandler} onFinishFailed={() => messageApi.error('Invalid Study')} layout={'inline'} size="small">
      <Form.Item label="Add Study" name="name" required={true}>
        <Input disabled={createStudyLoading} onChange={({ target }) => setValue(target.value)} value={value} />
      </Form.Item>
      <Form.Item style={{ marginRight: 0, width: 'unset' }}>
        <TooltipButton buttonProps={{ htmlType: "submit", disabled: !value || createStudyLoading, style: { border: 'none', background: 'none', padding: '0', width: 'unset' }, icon: <PlusCircleOutlined style={{ color: `${color.blue}${!value ? '80' : ''}` }} /> }} tooltipProps={{ title: !value ? 'You must enter a study name' : 'Create Study', arrow: { pointAtCenter: true }, placement: 'bottomRight' }} />
      </Form.Item>
    </Form>
  )
}

const StudiesTitle = () => <Title level={3} style={{ margin: 0 }}>Studies</Title>

const StudiesLoader = () => <Loader className={styles.StudiesLoader} />

const StudiesError = () => <Paragraph className={styles.StudiesError} style={{ color: color.red }}>There was an error fetching</Paragraph>

const STUDIES_EMPTY_TEXT = "No Studies"

const StudiesEmpty = () => <Empty description={STUDIES_EMPTY_TEXT} />

const StudiesSection: FC<SpaceProps> = props => <Space direction="vertical" className={styles.StudiesSection} {...props} />

const StudiesSectionTitle: FC<{ children: React.ReactNode }> = props => <Title level={5} {...props} />

export const Studies: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { isLoading: studiesLoading, isError: studiesError, data: studies } = getStudies(organizationId);

  const history = useHistory()

  const handleStudyCardClick: (study: Study) => void = study => {
    history.push(`/organizations/${organizationId}/studies/${study.id}`)
  }

  const openStudies = studies?.filter(({ is_complete }) => !is_complete)
  const closedStudies = studies?.filter(({ is_complete }) => is_complete)

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 24 }}>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <Space direction="vertical">
          <StudiesTitle />
          {studiesError && <Alert type="error" showIcon message="Error loading studies" />}
        </Space>
        <CreateStudy />
      </Space>

      {
        studiesLoading ? <StudiesLoader /> :
          studiesError ? <StudiesError /> :
            studies?.length === 0 ? <StudiesEmpty /> :
              <Space direction="vertical" style={{ width: '100%' }}>
                <StudiesSection>
                  <StudiesSectionTitle>Open Studies</StudiesSectionTitle>

                  <Row gutter={[16, 16]}>
                    {openStudies?.map(study => (
                      <Col sm={12} md={8} key={study.id}>
                        <ClickableCard onClick={handleStudyCardClick.bind(null, study)}>
                          <Space style={{ width: "100%", justifyContent: "space-between" }}>
                            <Text>{study.name}</Text>
                            {study.is_enrolling && (
                              <Tooltip title="Enrolling">
                                <UserAddOutlined />
                              </Tooltip>
                            )}
                          </Space>
                        </ClickableCard>
                      </Col>
                    ))}
                  </Row>
                </StudiesSection>

                {(closedStudies?.length ?? 0) > 0 && (
                  <StudiesSection>
                    <StudiesSectionTitle>Closed Studies</StudiesSectionTitle>

                    <Row gutter={[16, 16]}>
                      {closedStudies?.map(study => (
                        <Col sm={12} md={8} key={study.id}>
                          <ClickableCard
                            onClick={handleStudyCardClick.bind(null, study)}
                          >
                            {study.name}
                          </ClickableCard>
                        </Col>
                      ))}
                    </Row>
                  </StudiesSection>
                )}
              </Space>
      }
    </Space>
  )
}
