import React, { Dispatch, SetStateAction } from "react";
import MDEditor, { commands, ICommand, getCommands, TextState, TextAreaTextApi } from "@uiw/react-md-editor";

const commandStyle: React.CSSProperties = {
  textAlign: "left",
  fontSize: 12,
}

const makeCommand = (name: string, title: string, placeholder: string): ICommand => {
  return {
    name,
    keyCommand: name,
    icon: <div style={commandStyle}>{title}</div>,
    execute: (state: TextState, api: TextAreaTextApi) => {
      api.replaceSelection(`${state.selectedText} {${placeholder}}`)
    },
    buttonProps: { "aria-label": title, title }
  }
}

const AutofillCommandConfig = {
  study: [
    makeCommand("orgName", "Insert Organization Name", "ORG_NAME"),
    makeCommand("studyName", "Insert Study Name", "STUDY_NAME"),
    makeCommand("participantId", "Insert Participant ID", "PARTICIPANT_ID"),
    makeCommand("displayName", "Insert Participant Name", "NAME"),
    makeCommand("nextVisit", "Insert Next Visit Date", "NEXT_VISIT"),
  ],
  reports: [
    makeCommand("reconstructionImageTop", "Reconstruction Image Top", "reconstruction:snapshots/top.png:image/png"),
    makeCommand("reconstructionImageLeft", "Reconstruction Image Left", "reconstruction:snapshots/left.png:image/png"),
    makeCommand("reconstructionImageRight", "Reconstruction Image Right", "reconstruction:snapshots/right.png:image/png"),
    makeCommand("reconstructionImageFront", "Reconstruction Image Front", "reconstruction:snapshots/front.png:image/png"),
    makeCommand("reconstructionImageBack", "Reconstruction Image Back", "reconstruction:snapshots/back.png:image/png"),
    makeCommand("analysisTaskCongOnlyAccuracy", "Analysis Task Cong Only Accuracy", "analysis_task:task_metrics.json:application/json:type_outcomes:cong_only:accuracy"),
    makeCommand("analysisTaskMixedAccuracy", "Analysis Task Mixed Accuracy", "analysis_task:task_metrics.json:application/json:type_outcomes:mixed:accuracy"),
  ],
} as const;

type EditorProps = {
  value?: string
  onChange: Dispatch<SetStateAction<string>>
  height: number
  autofillCommands: keyof typeof AutofillCommandConfig
}

const CustomMDEditor: React.FC<EditorProps> = ( { value, onChange: setValue, height, autofillCommands }: EditorProps ) => {
  const defaultCommands = getCommands();

  const customCommands: ICommand[] = [
    ...defaultCommands,
    commands.divider,
    commands.group(
      [...AutofillCommandConfig[autofillCommands]],
      {
        name: "autofill",
        groupName: "autofill",
        icon: <div style={commandStyle}>AUTOFILL</div>,
        buttonProps: { "aria-label": "Autofill" }
      }
    ),
  ]

  return (
    <div data-color-mode="dark">
      <MDEditor className="wmde-markdown-var" height={height} value={value} onChange={value => setValue(value ?? '')} commands={customCommands} />
    </div>
  )
}

export default CustomMDEditor